import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import globals from 'browser/globals';
import { Alert, Button, Dialog, LoadingSpinner } from 'components/foundation';
import DatabaseName from 'application/db-name';
import { useDatabaseState } from 'store';
import { Database } from 'entities/database';
import tracking from 'actions/user-tracking-actions';
import Actions from 'actions';
import { CopyInput } from 'components/application/copy-input';
import DatabasesResource from 'remote/resources/databases';
import logger from 'logger';

interface Props {
  loading: boolean;
  error: string;
  database: Database;
  password: string;
  boltUrl: string;
  username: string;
}

// Exported for testing
export const CreatingDatabaseModal = ({
  loading,
  error,
  database,
  password,
  boltUrl,
  username,
}: Props) => {
  const credentialsFilename = useMemo(() => {
    if (!database) {
      return '';
    }
    return `Neo4j-${database.DbId}-Created-${new Date().toISOString().slice(0, 10)}.txt`;
  }, [database]);

  const credentialsURL = useMemo(() => {
    if (!database) {
      return '';
    }
    const content =
      '# Wait 60 seconds before connecting using these details, or login to https://console.neo4j.io to validate the Aura Instance is available\n' +
      `NEO4J_URI=${boltUrl}\n` +
      `NEO4J_USERNAME=${username}\n` +
      `NEO4J_PASSWORD=${password}\n` +
      `AURA_INSTANCEID=${database?.DbId}\n` +
      `AURA_INSTANCENAME=${database?.Name}\n`;
    const blob = new Blob([content], { type: 'text/plain' });
    return globals.URL.createObjectURL(blob);
  }, [password, database]);

  return (
    <Dialog
      modalProps={{
        className: 'generated-password-modal tw-h-[464px]',
        'data-testid': 'modal',
      }}
      size="small"
      open
      disableCloseButton
    >
      {database && (
        <Dialog.Header className="tw-truncate">
          Credentials for <DatabaseName Name={database.Name} DbId={database.DbId} />
        </Dialog.Header>
      )}
      <Dialog.Content className="tw-space-y-6">
        {loading && <LoadingSpinner size="large" expand />}
        {error && <Alert type="danger" description={error} />}
        {database && (
          <>
            <div className="readonly-field">
              <p>Username: {username}</p>
            </div>
            <div className="tw-flex">
              <div className="readonly-field tw-flex-1 tw-mr-2">
                <CopyInput
                  value={password}
                  label="Generated password"
                  id="generated-password"
                  readOnly
                />
              </div>
            </div>
            <Alert
              type="warning"
              description="Note that the password will not be available after this point."
              icon
            />
          </>
        )}
      </Dialog.Content>

      {loading || (
        <Dialog.Actions>
          <Button
            fill="outlined"
            color="neutral"
            onClick={backToDatabasesPage}
            data-testid="close-password-modal"
          >
            Close
          </Button>
          {database && (
            <Button
              data-testid="download-and-continue"
              href={credentialsURL}
              onClick={() => {
                tracking.trackEvent('DWNLD_PWD');
                backToDatabasesPage();
              }}
              download={credentialsFilename}
            >
              Download and continue
            </Button>
          )}
        </Dialog.Actions>
      )}
    </Dialog>
  );
};

const NewDatabasePage = ({ dbId }) => {
  const { newDbPassword, newDbBoltUrl, newDbUsername } = useDatabaseState();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(null);
  const [database, setDatabase] = useState<Database>(null);

  const generalError =
    "We couldn't retrieve the details for your new database due to a system error. " +
    'You may need to destroy the new database if it exists, and then try creating it again.';

  useEffect(() => {
    if (newDbPassword) {
      setLoading(true);
      DatabasesResource.get(dbId)
        .then(db => setDatabase(db))
        .catch(e => {
          logger.error(e);
          setDatabase(null);
          setError(generalError);
        })
        .finally(() => setLoading(false));
    } else {
      setDatabase(null);
      setError(generalError);
      setLoading(false);
    }
  }, [dbId]);

  return (
    <CreatingDatabaseModal
      loading={loading}
      error={error}
      password={newDbPassword}
      username={newDbUsername}
      database={database}
      boltUrl={newDbBoltUrl}
    />
  );
};

const backToDatabasesPage = () => {
  Actions.navigate.replace({ hash: '#databases' });
};

NewDatabasePage.propTypes = {
  dbId: PropTypes.string.isRequired,
};

export default NewDatabasePage;
