import DatabasesResource from 'remote/resources/databases';
import { DatabasesStore, SessionStore } from 'state';
import { CloudProvider, Tier } from 'entities/database';

import Actions from 'actions';

import logger from 'logger';

interface Parameters {
  Tier: Tier;
  Name: string;
  Region: string;
  Version: string;
  Namespace: string;
  Memory?: string;
  SourceSnapshot?: {
    DbId: string;
    SnapshotId?: string;
  };
  CloudProvider: CloudProvider;
  EncryptionKeyRef?: string;
  Confirmed?: boolean;
  IsTrial?: boolean;
}

const FREE_TIER_MEMORY = '1GB';

const createDatabase = async (parameters: Parameters): Promise<void> => {
  const databaseName = parameters.Name.trim();

  let memory: string;
  if (parameters.Tier === Tier.FREE) {
    memory = FREE_TIER_MEMORY;
  } else if (parameters.Memory) {
    memory = parameters.Memory;
  } else {
    throw Error('Missing required database memory parameter');
  }

  const result = await DatabasesResource.create({
    ...parameters,
    Name: databaseName,
    Memory: memory,
  });
  const dbid = result.DbId;
  const password = result.Password;
  const boltUrl = result.BoltUrl;
  const username = result.Username;

  if (parameters.Tier === Tier.FREE) {
    Actions.namespaces
      .refresh()
      .catch(error => logger.error('Failed to refresh current tenant:', error));
  }

  DatabasesStore.stashNewDbCredentials(password, boltUrl, username);
  SessionStore.setSizeEstimate(null);

  Actions.navigate.push({ hash: `#databases/${dbid}/created` });
};

export default createDatabase;
