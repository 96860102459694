import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Page, Divider, Button, LoadingSpinner, Alert } from 'foundation';

import Actions from 'actions';
import CreateDatabaseForm, {
  validate as validateCreate,
  defaultsForTier,
} from 'application/create-db/form-wrapper';
import track, { useTracking } from 'react-tracking';
import { filterSizeMap, Tier } from 'entities/database';
import { useLocation, useNeedsBilling, usePermissions, useSession } from 'store';
import { scrollToFirstError } from 'components/utils';
import { useUpdateEffect } from 'utils/hooks';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from 'application/create-db/form-data';
import './databases.css';
import { useDefaultErrorHandler } from 'remote/error-handler';
import StripeForm from 'application/billing-form';
import { Product } from 'types/product';
import {
  getAvailableTiers,
  filterProviderConfigFromTenant,
  getCloudProvider,
  getProductFromTier,
  PlanType,
  BillingMethod,
} from 'entities/tenant';
import { NEO4J_MANAGED_KEY } from 'types/encryption-keys';
import NotFoundPage from 'pages/not-found';
import { parse } from 'query-string';
import { omit } from 'utils/javascript';
import { getAvailableVersions } from 'utils/neo4j-versions';
import { SessionState } from 'state/session-store';
import { Action } from 'types/user';
import { Validation } from 'utils/validation';
import {
  profileInformationDefaults,
  ProfileInformationFormData,
} from 'components/application/create-db/profile-information/form-profile-information-data';
import { ProfileInformationForm } from 'components/application/create-db/profile-information/form-profile-information';
import { checkTrialCustomerInfoCanBeSubmitted } from 'remote/resources/organizations';

enum Step {
  EnterDatabaseDetails = 'instance-configuration',
  EnterCreditCardDetails = 'billing',
  EnterProTrialCustomerInfo = 'pro-trial-customer-info',
}

const DEFAULT_TIER_CALCULATORS: Record<
  Product,
  Record<PlanType, (session: SessionState) => Tier>
> = {
  [Product.AURA_DB]: {
    [PlanType.SELF_SERVE]: () => Tier.PROFESSIONAL,
    [PlanType.ENTERPRISE]: () => Tier.ENTERPRISE,
  },
  [Product.AURA_DS]: {
    [PlanType.SELF_SERVE]: () => Tier.GDS,
    [PlanType.ENTERPRISE]: () => Tier.AURA_DSE,
  },
};

export const determineTier = (session: SessionState) => {
  return DEFAULT_TIER_CALCULATORS[session.product][session.planType](session);
};

const useFormOptions = () => {
  const session = useSession();
  const cloudProvider = getCloudProvider(session.providerConfigs);
  const tenantType = session.tenant.tenantType;
  const tier = determineTier(session);
  const neo4jVersions = getAvailableVersions(session.providerConfigs, cloudProvider, tier);

  const formOptions: CreateDatabaseFormOptions = {
    planType: session.planType,
    product: session.product,
    neo4jVersions,
    databaseSizes: filterSizeMap(
      session.databaseSizes,
      size =>
        !size.deprecated &&
        size.cloud_providers.includes(cloudProvider) &&
        (session.tenant.availableActions.create_pro_trial.enabled ? true : !size.is_trial)
    ),
    tenant: session.tenant,
    providerConfigs: filterProviderConfigFromTenant(session.providerConfigs, tenantType),
    disableFreeDatabaseCreationReason: session.allowFreeDatabaseCreation
      ? null
      : 'You have reached the Free instance limit',
  };

  return formOptions;
};

const QueryParamsGuard = ({ tier = null, ...rest }) => {
  const session = useSession();
  const location = useLocation();
  const defaultTier = determineTier(session);

  const stepQueryParam = parse(location.search).step;
  let step: Step;
  switch (stepQueryParam) {
    case '1':
    case Step.EnterDatabaseDetails:
      step = Step.EnterDatabaseDetails;
      break;
    case '2':
    case Step.EnterCreditCardDetails:
      step = Step.EnterCreditCardDetails;
      break;
    case Step.EnterProTrialCustomerInfo:
      step = Step.EnterProTrialCustomerInfo;
      break;
    default:
      step = Step.EnterDatabaseDetails;
  }
  const [stepCheck, setStepCheck] = useState(false);
  const { allow } = usePermissions();
  const allowCreateInstances = allow(
    Action.CREATE,
    `namespaces/${session.currentTenant}/databases`
  );

  const availableTiers = getAvailableTiers(session);

  // Navigate to default tier if its the only one to choose.
  useEffect(() => {
    if (!tier) {
      Actions.navigate.replace({ hash: `#create-database/${defaultTier}` });
    }
  }, [tier, availableTiers]);

  // This guard is only rendered one time for the entire form flow. When it is initially rendered,
  // reset the step (if provided) so that users can't end up accidentaly on the billing step.
  // Since this hook only runs on initial form render, step will be tracked normally for the
  // duration of the form flow.
  // Essentially, this scopes the step to only be useful when on any of the create form pages.
  // Once you go to any page outside of the create flow, you have to start over again.
  useEffect(() => {
    const search = parse(location.search);
    if (search.step) {
      Actions.navigate.replace({ ...location, search: omit(search, ['step']) });
    }
    setStepCheck(true);
  }, []);

  if (!stepCheck) {
    return <LoadingSpinner size="large" expand />;
  }

  if (tier && !availableTiers.has(tier)) {
    return <NotFoundPage />;
  }

  if (!allowCreateInstances) {
    return <Alert type="danger">You do not have sufficient permissions to access this page.</Alert>;
  }

  return <CreateDatabasePage {...rest} tier={tier ?? defaultTier} step={step} />;
};

const CreateDatabasePage = ({ tier, step }) => {
  const session = useSession();
  const tracking = useTracking();
  const location = useLocation();
  const availableTiers: Set<Tier> = getAvailableTiers(session);
  const [validation, setValidation] = useState<Validation<CreateDatabaseFormData>>(null);
  const [loading, setLoading] = useState(false);
  const [customerInfoLoading, setCustomerInfoLoading] = useState(false);
  const [customerInfoCanBeSubmitted, setCustomerInfoCanBeSubmitted] = useState(false);
  const permission = usePermissions();

  const defaultErrorHandler = useDefaultErrorHandler();

  const [formOptions, setFormOptions] = useState(useFormOptions());

  const getFormDefaults = () => {
    // This empty object casting is a bit clunky, is there a better way?
    return tier ? defaultsForTier(formOptions, tier) : ({} as CreateDatabaseFormData);
  };

  const [data, setData] = useState<CreateDatabaseFormData>(() => getFormDefaults());

  useEffect(() => {
    const canReadTrialCustomerInfo = (orgId: string) =>
      permission.allow(Action.READ, `organizations/${orgId}/trial/customer-info`);
    if (
      session.tenant.organizationId &&
      session.tenant.availableActions.create_pro_trial.enabled &&
      canReadTrialCustomerInfo(session.tenant.organizationId)
    ) {
      setCustomerInfoLoading(true);
      checkTrialCustomerInfoCanBeSubmitted(session.tenant.organizationId)
        .then(result => {
          setCustomerInfoCanBeSubmitted(result);
        })
        .catch(() => {
          setCustomerInfoCanBeSubmitted(false);
        })
        .finally(() => {
          setCustomerInfoLoading(false);
        });
    }
  }, [session.tenant.organizationId, session.tenant.availableActions.create_pro_trial.enabled]);

  const customerInfoShouldBeCollected =
    customerInfoCanBeSubmitted &&
    session.tenant.availableActions.create_pro_trial.enabled &&
    data.size?.is_trial;

  const [profileInformation, setProfileInformation] = useState<ProfileInformationFormData>({
    ...profileInformationDefaults,
  });

  // If the user hasn't selected a tier yet, just fallback to any tier
  // that doesn't need billing because this is N/A
  const needsBillingStep = useNeedsBilling(data.tier ?? Tier.FREE) && !data.size?.is_trial;

  let expectedSteps: Step[];
  if (needsBillingStep) {
    expectedSteps = [Step.EnterDatabaseDetails, Step.EnterCreditCardDetails];
  } else if (customerInfoShouldBeCollected) {
    expectedSteps = [Step.EnterDatabaseDetails, Step.EnterProTrialCustomerInfo];
  } else {
    expectedSteps = [Step.EnterDatabaseDetails];
  }
  const currentStepNumber = expectedSteps.indexOf(step) + 1;

  const trackCurrentStep = ({ isComplete = true }) => {
    tracking.trackEvent({
      action: isComplete ? 'CREATE_DB_STEP_COMPLETE' : 'CREATE_DB_STEP_CANCEL',
      properties: {
        currentStep: step,
        needsBillingStep,
      },
    });
  };

  useUpdateEffect(() => {
    setData(getFormDefaults());
  }, [session.product, tier]);

  useEffect(() => {
    if (tier && data.cloudProvider) {
      setFormOptions({
        ...formOptions,
        neo4jVersions: getAvailableVersions(session.providerConfigs, data.cloudProvider, tier),
        databaseSizes: filterSizeMap(
          session.databaseSizes,
          size =>
            !size.deprecated &&
            size.cloud_providers.includes(data.cloudProvider) &&
            (session.tenant.availableActions.create_pro_trial.enabled ? true : !size.is_trial)
        ),
      });
    }
  }, [tier, data.cloudProvider]);

  const canGoBackToTierSelection = tier && availableTiers.size > 1;

  const handleDataChange = (newData: CreateDatabaseFormData, forceSubmit = false) => {
    if (!forceSubmit) {
      setData(newData);
    }

    if (validation) {
      setValidation(validateCreate(newData, formOptions));
    }
    if (forceSubmit) {
      handleSubmit(undefined, newData);
    }
  };

  const handleBackToTierSelection = () => {
    Actions.navigate.push({ hash: '#databases/create' });
  };

  const handleCancel = () => {
    trackCurrentStep({ isComplete: false });
    Actions.navigate.push({ hash: '#databases' });
  };

  // We have this function because the Form component calls the on submit
  // with two args but we only want it to call it with one.
  const handleFormSubmit = (e: SyntheticEvent) => {
    handleSubmit(e);
  };

  const handleSubmit = (e?: SyntheticEvent, submitData: CreateDatabaseFormData = undefined) => {
    const dataToSubmit = submitData ?? data;
    if (e) {
      e.preventDefault();
    }

    const errors = validateCreate(dataToSubmit, formOptions);
    setValidation(errors);
    if (errors) {
      scrollToFirstError();
      return;
    }
    if (currentStepNumber < expectedSteps.length) {
      const product = getProductFromTier(dataToSubmit.tier);
      const currentStepIndex = expectedSteps.indexOf(step);
      const nextStep = expectedSteps[currentStepIndex + 1];
      Actions.navigate.push({ search: { step: nextStep, product: product } });
    } else {
      createDatabase(dataToSubmit);
    }
  };

  const handleBackOneStep = () => {
    trackCurrentStep({ isComplete: false });
    const search = parse(location.search);
    const currentStepIndex = Math.max(1, expectedSteps.indexOf(step));
    const previousStep = expectedSteps[currentStepIndex - 1];
    Actions.navigate.replace({ search: { ...search, step: previousStep } });
  };

  const handleStripeSuccess = () => {
    trackCurrentStep({ isComplete: true });
    createDatabase(data);
  };

  const handleEnterProTrialCustomerInfoCancel = () => {
    const search = parse(location.search);
    Actions.navigate.replace({ search: { ...search, step: undefined } });
  };

  const handleProfileInformationChange = (profileInformationData: ProfileInformationFormData) => {
    setProfileInformation(profileInformationData);
  };

  const handleProfileInformationSubmit = () => {
    trackCurrentStep({ isComplete: true });

    createDatabase(data);
  };

  const createDatabase = dataToSubmit => {
    setLoading(true);

    const encryptionKeyRef: string | null =
      dataToSubmit.encryptionKeyRef === NEO4J_MANAGED_KEY ? null : dataToSubmit.encryptionKeyRef;
    const enableCmek =
      session?.featureToggles?.enable_cmek &&
      [Tier.ENTERPRISE, Tier.AURA_DSE].includes(dataToSubmit.tier);

    const requestArgs = {
      Name: dataToSubmit.name,
      Tier: dataToSubmit.tier,
      Region: dataToSubmit.region,
      Namespace: session.currentTenant,
      CloudProvider: dataToSubmit.cloudProvider,
      ...(dataToSubmit.tier === Tier.FREE && {
        Version: dataToSubmit.version,
      }),
      ...(dataToSubmit.tier !== Tier.FREE && {
        Version: dataToSubmit.version,
        Memory: dataToSubmit.size.memory,
      }),
      ...(enableCmek && {
        EncryptionKeyRef: encryptionKeyRef,
        Confirmed: true,
      }),
      ...(session.tenant.availableActions.create_pro_trial.enabled &&
        [Tier.PROFESSIONAL, Tier.GDS].includes(data.tier) && {
          IsTrial: data.size.is_trial,
        }),
    };

    const event = {
      tier: requestArgs.Tier,
      version: requestArgs.Version,
      region: requestArgs.Region,
      ...(requestArgs.Memory && { size: requestArgs.Memory }),
      ...(requestArgs.EncryptionKeyRef && {
        encryptionKeyRef: requestArgs.EncryptionKeyRef,
      }),
      ...([Tier.PROFESSIONAL, Tier.GDS].includes(data.tier) && {
        proTrial: data.size.is_trial ? 'proTrial_included' : 'proTrial_excluded',
      }),
    };

    tracking.trackEvent({
      action: 'create_db',
      properties: event,
    });

    Actions.databases
      .createDatabase(requestArgs)
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false);
        return defaultErrorHandler(error);
      });
  };

  const isSubmitDisabled = useMemo(() => {
    if (session.tenant.suspended) return true;
    if (loading || (!data.confirmed && ![Tier.GDS, Tier.AURA_DSE].includes(data.tier))) return true;
    const errors = validateCreate(data, formOptions, { onlyRequired: true });
    if (errors) return true;
    if (data.size?.prepaid_only && session.tenant.billingMethod !== BillingMethod.PREPAID) {
      return true;
    }
    return false;
  }, [loading, data]);

  const showTierSelection = !data?.tier;

  const shouldShowHeader =
    formOptions.planType === PlanType.ENTERPRISE ||
    formOptions.product === Product.AURA_DS ||
    !showTierSelection;

  // Wait for size estimate calculation before showing create db button for AuraDS
  // Or never show the buttons because we are in the Free tier and the optimized create
  // flow does not need these buttons
  const hideButtons =
    showTierSelection || data.tier === Tier.FREE || (!data.size && data.tier === Tier.AURA_DSE);

  const shouldBePlain = showTierSelection;

  return (
    <Page plain={shouldBePlain} className="tw-pt-14 tw-flex" style={{ placeContent: 'center' }}>
      {customerInfoLoading ? (
        <LoadingSpinner size="large" expand />
      ) : (
        <div
          className={shouldShowHeader ? 'create-db' : 'create-db-self-serve-page'}
          data-testid="create-db-all-steps-container"
        >
          {step === Step.EnterDatabaseDetails && (
            <form
              className="create-db-form"
              data-testid="create-db-content"
              onSubmit={handleFormSubmit}
            >
              {shouldShowHeader && <h4 className="tw-mb-8">Create an instance</h4>}
              {expectedSteps.length > 1 && (
                <div className="tw-text-center">
                  <p className="tw-mt-2">
                    <b>
                      Step {currentStepNumber} of {expectedSteps.length}
                    </b>
                  </p>
                  <Divider hidden />
                </div>
              )}
              <CreateDatabaseForm
                data={data}
                options={formOptions}
                validation={validation}
                onChange={handleDataChange}
                disabled={loading}
              />
              {!hideButtons && (
                <div className="tw-flex tw-justify-end tw-my-8">
                  <Button
                    className="tw-mr-4"
                    onClick={canGoBackToTierSelection ? handleBackToTierSelection : handleCancel}
                    data-testid="create-cancel"
                    color="neutral"
                    fill="outlined"
                  >
                    {canGoBackToTierSelection ? 'Back' : 'Cancel'}
                  </Button>
                  <Button
                    disabled={isSubmitDisabled}
                    loading={loading}
                    data-testid="create-database"
                    onClick={handleSubmit}
                  >
                    {currentStepNumber < expectedSteps.length ? 'Next' : 'Create'}
                  </Button>
                </div>
              )}
            </form>
          )}
          {step === Step.EnterCreditCardDetails && (
            <>
              <div className="tw-mb-4">
                <h4 className="tw-mb-8">Create an instance</h4>
                <p className="tw-mt-2 tw-text-center">
                  <b>
                    Step {currentStepNumber} of {expectedSteps.length}
                  </b>
                </p>
              </div>
              <StripeForm
                cancelButtonText="Back"
                submitButtonText="Create"
                onCancel={handleBackOneStep}
                onSuccess={handleStripeSuccess}
              />
            </>
          )}
          {step === Step.EnterProTrialCustomerInfo && (
            <>
              <div className="tw-mb-4">
                <h4 className="tw-mb-8">Create an instance</h4>
                <p className="tw-mt-2 tw-text-center">
                  <b>Step 2 of 2</b>
                </p>
              </div>
              <ProfileInformationForm
                data={profileInformation}
                onChange={handleProfileInformationChange}
                onCancel={handleEnterProTrialCustomerInfoCancel}
                onSubmit={handleProfileInformationSubmit}
                setLoading={setLoading}
                loading={loading}
              />
            </>
          )}
        </div>
      )}
    </Page>
  );
};

export default track({ page: 'create-database' })(QueryParamsGuard);
