import ApprovalRequiredPage from 'pages/login/unauthorised';
import AccountTerminatedPage from 'pages/login/account-terminated';
import LoginErrorPage from 'pages/login/login-error';
import TermsAndConditionsPage from 'pages/tsandcs';
import GdprPage from 'pages/gdpr';
import { SessionStore } from 'state';
import { match } from './helpers';
import NotFoundPage from 'pages/not-found';
import React from 'react';
import { OrganizationLookupPage } from '../organization-lookup';

export const publicRoutes = [
  {
    description: 'Organization Lookup page',
    routeMatcher: match(/organization-lookup/),
    requiresLogin: false,
    minimalView: true,
    render: () => <OrganizationLookupPage />,
  },
  {
    description: 'Not Found Page',
    requiresLogin: false,
    routeMatcher: match(/not-found/),
    render: () => <NotFoundPage />,
  },
  {
    description: 'GDPR',
    routeMatcher: match(/gdpr/),
    requiresLogin: false,
    render: () => {
      window.location.replace('https://trust.neo4j.com/');
      return <GdprPage />;
    },
  },
  {
    description: 'Unauthorised Page',
    routeMatcher: match(/approval-required/),
    requiresLogin: false,
    render: () => <ApprovalRequiredPage />,
  },
  {
    description: 'Account Terminated Page',
    routeMatcher: match(/account-terminated/),
    requiresLogin: false,
    render: () => <AccountTerminatedPage />,
  },
  {
    description: 'Generic Login Error Page',
    routeMatcher: match(/login-error/, /.*/),
    requiresLogin: false,
    loader: ({ instanceId }) => ({ errorCode: instanceId }),
    render: ({ errorCode }) => <LoginErrorPage errorCode={errorCode} />,
  },
  {
    description: 'Public Terms and Conditions Page',
    routeMatcher: match(/terms/),
    requiresLogin: false,
    stateMatcher: () => !SessionStore.state.acceptedTsAndCs,
    render: () => <TermsAndConditionsPage loggedIn={false} />,
  },
];
