import React from 'react';

import { Page } from 'foundation/page';
import { Link } from 'components/foundation';

const GdprPage = () => (
  <Page>
    <h4>Subprocessors</h4>
    <p className="tw-mt-2">
      A full list of Aura Subprocessors can be found at{' '}
      <Link href="https://trust.neo4j.com/" newTab>
        https://trust.neo4j.com/
      </Link>
    </p>
  </Page>
);

export default GdprPage;
