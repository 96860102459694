import {
  AvailableTenantActions,
  BillingMethod,
  ProviderConfigs,
  Tenant,
  TenantCapabilities,
  TenantNotification,
  TenantProfile,
  TenantSummary,
  TenantType,
  TierConfigsV2,
} from 'entities/tenant';
import ApiClient from 'remote/api-client';
import { useQuery, UseQueryResult } from 'react-query';
import { useSession } from 'store';
import { PlanType } from 'types/organization';
import { RoleName } from 'types/user';

export interface TenantSummaryResponse {
  NamespaceId: string;
  FriendlyName: string;
  NamespaceType: TenantType;
  PlanType: PlanType;
  Suspended: boolean;
  HasBilling: boolean;
  RequiresBilling: boolean;
  BillingMethod?: BillingMethod;
  OrganizationId?: string;
  SsoOrganizationId?: string;
  GoogleProjectId?: string;
}

const transformTenantSummary = (data: TenantSummaryResponse): TenantSummary => {
  const result: TenantSummary = {
    id: data.NamespaceId,
    name: data.FriendlyName,
    tenantType: data.NamespaceType,
    planType: data.PlanType,
    suspended: data.Suspended,
    hasBilling: data.HasBilling,
    requiresBilling: data.RequiresBilling,
    billingMethod: data.BillingMethod,
    organizationId: data.OrganizationId,
    ssoOrganizationId: data.SsoOrganizationId,
  };
  if (data.GoogleProjectId) {
    result.googleProjectId = data.GoogleProjectId;
  }

  return result;
};

export const listByUserId = async (userId: string): Promise<TenantSummary[]> => {
  const tenants = await ApiClient.get(`/users/${userId}/tenants`).promise();

  return tenants.map(tenant => transformTenantSummary(tenant));
};

export const listByOrganizationId = async (organizationId: string): Promise<TenantSummary[]> => {
  const tenants = await ApiClient.get(`/organizations/${organizationId}/tenants`)
    .ignoreAllErrors()
    .promise();

  return tenants.map(tenant => transformTenantSummary(tenant));
};

export const searchOrgTenants = async (
  organizationId: string,
  ssoConfigId: string
): Promise<TenantSummary[]> => {
  const tenants = await ApiClient.get(
    `/organizations/${organizationId}/tenants?sso_config_id=${ssoConfigId}`
  )
    .ignoreAllErrors()
    .promise();

  return tenants.map(tenant => transformTenantSummary(tenant));
};

export interface TenantResponse extends TenantSummaryResponse {
  AllowFreeDatabaseCreation: boolean;
  AvailableNeo4jVersions: string[];
  ProviderConfigs: ProviderConfigs;
  TierConfigs: TierConfigsV2;
  UnavailableTiers: TierConfigsV2;
  Capabilities: TenantCapabilities;
  HasUnpaidInvoices?: boolean;
  NamespaceProfile: TenantProfile;
  AvailableActions: AvailableTenantActions;
  Notifications: TenantNotification[];
  AvailableRoles: RoleName[];
}

export const get = async (tenantId: string): Promise<Tenant> => {
  const tenant: TenantResponse = await ApiClient.get(`/tenants/${tenantId}`).promise();

  const result: Tenant = {
    id: tenant.NamespaceId,
    name: tenant.FriendlyName,
    tenantType: tenant.NamespaceType,
    planType: tenant.PlanType,
    suspended: tenant.Suspended,
    allowFreeDatabaseCreation: tenant.AllowFreeDatabaseCreation,
    availableNeo4jVersions: tenant.AvailableNeo4jVersions,
    providerConfigs: tenant.ProviderConfigs,
    tierConfigs: tenant.TierConfigs,
    unavailableTiers: tenant.UnavailableTiers,
    capabilities: tenant.Capabilities,
    hasUnpaidInvoices: tenant.HasUnpaidInvoices,
    hasBilling: tenant.HasBilling,
    billingMethod: tenant.BillingMethod,
    requiresBilling: tenant.RequiresBilling,
    tenantProfile: tenant.NamespaceProfile,
    availableActions: tenant.AvailableActions,
    notifications: tenant.Notifications,
    availableRoles: tenant.AvailableRoles,
  };

  if (tenant.OrganizationId) {
    result.organizationId = tenant.OrganizationId;
  }

  if (tenant.GoogleProjectId) {
    result.googleProjectId = tenant.GoogleProjectId;
  }

  return result;
};

export const getAll = async (userId: string): Promise<Tenant[]> => {
  const tenantSummaries = await listByUserId(userId);
  return Promise.all(tenantSummaries.map(ts => get(ts.id)));
};

export const useSearchOrganizationTenantsQuery = (
  organizationId: string,
  ssoConfigId: string
): UseQueryResult<TenantSummary[]> => {
  const query = async () => {
    const tenants = await searchOrgTenants(organizationId, ssoConfigId);
    return tenants;
  };
  return useQuery(`organization-tenants-${organizationId}-sso-${ssoConfigId}`, query, {
    enabled: !!organizationId,
  });
};

export const useOrganizationTenantsQuery = (
  organizationId: string
): UseQueryResult<TenantSummary[]> => {
  const query = async () => {
    const tenants = await listByOrganizationId(organizationId);
    return tenants;
  };

  return useQuery(`organization-tenants-${organizationId}`, query, {
    enabled: !!organizationId,
    refetchInterval: 10000,
  });
};

export const useTenantsQuery = (): UseQueryResult<Tenant[]> => {
  const session = useSession();

  const query = async (): Promise<Tenant[]> => {
    const tenants = await getAll(session.userId);
    return tenants;
  };

  return useQuery('tenants', query, { enabled: !!session.userId, refetchInterval: 20000 });
};

export const useTenantQuery = (
  tenantId: string,
  refetchInterval: number = 10000
): UseQueryResult<Tenant> => {
  const query = async () => {
    const tenant = await get(tenantId);
    return tenant;
  };

  return useQuery(`tenant-${tenantId}`, query, { enabled: !!tenantId, refetchInterval });
};

export const useTenantSummariesQuery = (): UseQueryResult<TenantSummary[]> => {
  const session = useSession();

  const query = async (): Promise<TenantSummary[]> => {
    const tenants = await listByUserId(session.userId);
    return tenants;
  };

  return useQuery('tenantSummaries', query, { enabled: !!session.userId, refetchInterval: 20000 });
};

export default {
  get,
  getAll,
  listByUserId,
  useTenantsQuery,
  useTenantQuery,
  useTenantSummariesQuery,
};
