import React from 'react';
import { Alert, FormSelect } from 'foundation';
import { useSession } from 'store';
import {
  EditSecondariesCountDatabaseFormData,
  EditSecondariesCountDatabaseFormDataCount,
} from 'application/edit-secondaries/form-data';

const MAX_SECONDARIES_COUNT = 15;

type SecondariesOption = {
  value: number;
  label: string;
  isDisabled: boolean;
  key: string;
};

type DatabaseSecondariesCountPickerProps = {
  value: number;
  options: EditSecondariesCountDatabaseFormDataCount[];
  initialCount: number | undefined;
  onChange: (value: EditSecondariesCountDatabaseFormDataCount) => void;
  errorMessage: string | undefined;
};

type EditSecondariesCountDatabaseFormProps = {
  data: EditSecondariesCountDatabaseFormData;
  initialCount: number | undefined;
  onChange: (value: EditSecondariesCountDatabaseFormData) => void;
  errorMessage?: string | null;
};

const availableSecondariesCount: EditSecondariesCountDatabaseFormDataCount[] = Array.from(
  { length: MAX_SECONDARIES_COUNT + 1 },
  (_, i) => ({
    secondariesCount: i,
  })
);

export const EditSecondariesCountDatabaseForm = ({
  onChange,
  data,
  initialCount,
  errorMessage = null,
}: EditSecondariesCountDatabaseFormProps) => {
  const handleSecondariesCountChange = (
    secondariesCount: EditSecondariesCountDatabaseFormDataCount
  ) => {
    onChange({ ...data, ...secondariesCount });
  };

  return (
    <DatabaseSecondariesCountPicker
      value={data.secondariesCount}
      onChange={handleSecondariesCountChange}
      options={availableSecondariesCount}
      initialCount={initialCount}
      errorMessage={errorMessage}
    />
  );
};

const DatabaseSecondariesCountPicker = ({
  value,
  options,
  initialCount,
  onChange,
  errorMessage,
}: DatabaseSecondariesCountPickerProps) => {
  const isOptionDisabled = (count: number) => initialCount !== undefined && count === initialCount;

  const secondariesOptions: SecondariesOption[] = options.map(({ secondariesCount }) => ({
    value: secondariesCount,
    label: `${
      secondariesCount > 0
        ? `${secondariesCount} ${secondariesCount === 1 ? 'secondary' : 'secondaries'}`
        : '0 (no secondaries)'
    }`,
    isDisabled: isOptionDisabled(secondariesCount),
    key: `${secondariesCount}`,
  }));

  const handleChange = (newValue: SecondariesOption) => {
    if (newValue.isDisabled) return;
    onChange({ secondariesCount: newValue.value });
  };

  const currentValue = secondariesOptions.find(v => v.value === value) ?? secondariesOptions[0];

  const { tenant } = useSession();
  const secondaryCost = tenant.tierConfigs.enterprise?.secondary_cost;

  return (
    <>
      <FormSelect
        value={currentValue}
        label="Secondaries Count"
        data-testid="dropdown-secondaries"
        options={secondariesOptions}
        searchable={false}
        onChange={handleChange}
      />
      {secondaryCost && (
        <Alert type="info" className="tw-mt-2">
          Secondaries are billed at ${secondaryCost}/GB/hr, per secondary, based on your current
          pricing plan.
        </Alert>
      )}
      {errorMessage && (
        <Alert type="danger" className="tw-mt-2">
          {errorMessage}
        </Alert>
      )}
    </>
  );
};
