import { IconButton, TextLink } from 'components/foundation';
import React, { useState } from 'react';
import { useSession } from 'store';
import UpxBannerBackground from 'ui/icons/upx-banner.svg';
import UpxBannerBackgroundDark from 'ui/icons/upx-banner-dark.svg';
import { useDarkTheme } from 'utils/hooks';
import { renderToStaticMarkup } from 'react-dom/server';

const UPX_PREVIEW_BANNER_VIEWED = 'upx-preview-banner';

const formatUpxDomain = (environment: string, neo4jDomain: string) => {
  const env = environment && environment.length === 0 ? 'production' : environment;
  const domain = neo4jDomain ?? 'neo4j.io';
  const subdomain = env === 'production' ? 'console-preview' : `console-preview-${env}`;
  return `${subdomain}.${domain}`;
};

export const UpxPreviewBanner = () => {
  const upxDomain = formatUpxDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
  const darkMode = useDarkTheme();
  const session = useSession();
  const [hasBannerBeenDismissed, setHasBannerBeenDismissed] = useState<boolean>(
    JSON.parse(global.localStorage.getItem(UPX_PREVIEW_BANNER_VIEWED) ?? 'false')
  );

  const svgString = encodeURIComponent(
    renderToStaticMarkup(darkMode ? <UpxBannerBackgroundDark /> : <UpxBannerBackground />)
  );
  if (hasBannerBeenDismissed || !session.tenant) {
    return null;
  }
  return (
    <>
      <div
        className="tw-flex tw-relative tw-w-full tw-bg-palette-neutral-bg-weak tw-h-16 tw-min-h-16 tw-justify-center tw-border-b tw-border-palette-neutral-border-weak"
        style={{
          backgroundImage: `url("data:image/svg+xml,${svgString}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="tw-font-syne tw-z-10 tw-self-center">
          Early access: check out the new Aura console.{' '}
          <TextLink
            href={`https://${upxDomain}/projects/${session.tenant.id}/instances`}
            className="tw-no-underline"
            externalLink
          >
            Preview now
          </TextLink>
        </div>
        <div className="tw-p-2 tw-absolute tw-right-0 tw-top-0 tw-bottom-0 tw-content-center">
          <IconButton
            iconName="XMarkIconOutline"
            clean
            aria-label="Dismiss new Aura Console banner"
            onClick={() => {
              global.localStorage.setItem(UPX_PREVIEW_BANNER_VIEWED, 'true');
              setHasBannerBeenDismissed(true);
            }}
          />
        </div>
      </div>
    </>
  );
};
