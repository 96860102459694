import { Database, DatabaseStatus } from 'entities/database';
import { boltConnectionValues } from 'utils/connection-uri-helper';
import BaseStore from './base-store';

export interface DatabasesState {
  databases: Database[];
  newDbPassword?: string;
  newDbBoltUrl?: string;
  newDbUsername?: string;
}

export interface ShadowSpecification {
  expiryTimeout: number;
  expiryStatusChange?: DatabaseStatus;
}

class DatabasesStore extends BaseStore<DatabasesState> {
  constructor() {
    super('DatabasesStore');
  }

  initialState() {
    return {
      databases: [],
    };
  }

  findById(dbid: string): Database {
    return this._find(this.state.databases, dbid);
  }

  upsert(dbId: string, database: Database) {
    const index = this.state.databases.findIndex(db => db.DbId === dbId);
    if (index === -1) {
      this._updateState({
        ...this.state,
        databases: [...this.state.databases, database],
      });
    } else {
      const databases = [...this.state.databases];
      databases[index] = database;
      this._updateState({
        ...this.state,
        databases: databases,
      });
    }
  }

  refresh(databases: Database[]) {
    const mappedDatabases = databases.map(database => {
      if (database.BoltUrl) {
        const ConnectionUri = boltConnectionValues(database.BoltUrl).withDefaultProtocol;
        return {
          ...database,
          ConnectionUri,
        };
      }
      return { ...database };
    });
    this._updateState({
      ...this.state,
      databases: mappedDatabases,
    });
  }

  stashNewDbCredentials(newDbPassword: string, newDbBoltUrl: string, newDbUsername: string) {
    this._updateState({
      ...this.state,
      newDbPassword,
      newDbBoltUrl,
      newDbUsername,
    });
  }

  _find(databases: Database[], dbid: string) {
    return databases.find(db => db.DbId === dbid);
  }

  _redactFilter(stateForLogging) {
    return this._redactField(stateForLogging, 'newDbPassword');
  }
}

export default DatabasesStore;
