import { PricingPlanName, TenantProfile } from 'entities/tenant';

export const tenantProfileOptions = [
  { label: 'Internal', value: TenantProfile.INTERNAL, key: TenantProfile.INTERNAL },
  { label: 'External', value: TenantProfile.EXTERNAL, key: TenantProfile.EXTERNAL },
];

export const pricingPlanOptions = [
  {
    key: PricingPlanName.LEGACY_ENTERPRISE,
    value: PricingPlanName.LEGACY_ENTERPRISE,
    label: 'Legacy Enterprise',
  },
  {
    key: PricingPlanName.VIRTUAL_DEDICATED_CLOUD,
    value: PricingPlanName.VIRTUAL_DEDICATED_CLOUD,
    label: 'Virtual Dedicated Cloud',
  },
];
