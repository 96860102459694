import React from 'react';
import { Divider, Alert, Checkbox } from 'foundation';
import * as yup from 'yup';

import { validateYup, Validation } from 'utils/validation';
import formatDollars from 'utils/format-dollars';

import { DatabaseNameInput } from 'application/create-db/form-essentials';
import { Tier } from 'entities/database';
import { CloneToNewDatabaseFormData, CloneToNewDatabaseFormOptions } from './form-data';
import { availableRegionsForTier, getCloudProvider, PlanType } from 'entities/tenant';
import { NEO4J_MANAGED_KEY } from 'types/encryption-keys';

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  confirmed: yup
    .bool()
    .required('You must check that your understand the costs of cloning a database')
    .isTrue('You must check that your understand the costs of cloning a database'),
});

export const validate = (
  data: CloneToNewDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => validateYup(schema, data, onlyRequired || false);

export const defaults = (options: CloneToNewDatabaseFormOptions): CloneToNewDatabaseFormData => {
  const tier = options.planType === PlanType.ENTERPRISE ? Tier.AURA_DSE : Tier.GDS;
  return {
    name: `${options.database.Name} Clone`,
    tier,
    region: availableRegionsForTier(options.providerConfigs, options.targetTier)[0]?.name,
    version: '5',
    size: options.databaseSizes[tier][0],
    confirmed: undefined,
    cloudProvider: getCloudProvider(options.providerConfigs),
    ...(options.planType === PlanType.ENTERPRISE ? { encryptionKeyRef: NEO4J_MANAGED_KEY } : {}),
  };
};

interface Props {
  data: CloneToNewDatabaseFormData;
  options: CloneToNewDatabaseFormOptions;
  onChange: (data: CloneToNewDatabaseFormData) => any;
  validation: Validation<CloneToNewDatabaseFormData>;
  disabled?: boolean;
}

const DataScienceFields = ({ data, onChange, validation, disabled }: Props) => {
  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });
  const handleConfirmedChange = ({ target: { checked } }) =>
    onChange({ ...data, confirmed: checked });

  const PriceDescription = ({ tier, memory, cost }) => {
    if (tier === Tier.AURA_DSE) {
      return <p className="tw-font-light">Please refer to your contract for pricing.</p>;
    }
    return (
      <p className="tw-font-light">
        This {memory} instance will cost {formatDollars(cost)} per hour while running.
      </p>
    );
  };

  return (
    <>
      <h5 className="tw-mb-4 tw-mr-2">Instance details</h5>

      <div>
        <Alert
          type="info"
          icon={true}
          description="Cloned AuraDS instances will create a version 5 Neo4j Database."
          className="tw-mb-6"
        />
      </div>

      <DatabaseNameInput
        value={data.name}
        validationError={validation?.name?.message}
        onChange={handleDatabaseNameChange}
        disabled={disabled}
      />

      <div className="tw-mx-auto console-w-fit">
        <Divider hidden />
        <PriceDescription
          tier={data.tier}
          memory={data.size.memory}
          cost={data.size.cost_per_hour}
        />
        <div className="tw-mt-4">
          <Checkbox
            label="I understand"
            checked={data.confirmed}
            onChange={handleConfirmedChange}
            disabled={disabled}
            data-testid="pricing-confirmation-check"
          />
        </div>
        {validation?.confirmed?.message && (
          <Alert type="danger" className="tw-mt-2">
            {validation?.confirmed?.message}
          </Alert>
        )}
      </div>
    </>
  );
};

export default DataScienceFields;
