import DatabaseName from 'application/db-name';
import React, { useMemo, useState } from 'react';
import { Database, findDatabaseSize, Tier } from 'entities/database';
import { formatSizeString } from 'components/utils';
import formatDollars from 'utils/format-dollars';
import { useSession } from 'store';
import { useTerminology } from 'utils/terminology';
import { Checkbox, Dialog, Alert, Button } from 'foundation';

interface Props {
  database: Database;
  open: boolean;
  loading: boolean;
  onCancelClick: () => void;
  onConfirmClick: () => void;
  error?: string;
  errorHeader?: string;
}

const ModalTextContent = ({ database }: { database: Database }) => {
  const session = useSession();
  const t = useTerminology();

  const sizesForTier = session.databaseSizes[database.Tier];
  const size = useMemo(() => findDatabaseSize(sizesForTier, database.DesiredSettings.Memory), [
    database,
  ]);

  const costPerHour = formatDollars(size?.cost_per_hour);
  const costPerMonth = formatDollars(parseFloat(size?.cost_per_hour) * 24 * 30);

  const memory = formatSizeString(database.DesiredSettings.Memory);

  if ([Tier.PROFESSIONAL, Tier.MTE].includes(database.Tier)) {
    return (
      <>
        You will be charged {costPerHour} per hour while an instance of this size is running, and
        continuously running this database for a 30 day month will cost you {costPerMonth}.
      </>
    );
  }

  if (database.Tier === Tier.GDS) {
    return (
      <>
        You will be charged {costPerHour} per hour while your {memory} instance is running.
      </>
    );
  }

  if ([Tier.ENTERPRISE, Tier.AURA_DSE].includes(database.Tier)) {
    return (
      <>
        <p>Resuming this {t('database')} will consume credits at the normal running rate.</p>
        <p>Please refer to your contract.</p>
      </>
    );
  }

  return null;
};

export const ResumeDatabaseModal = ({
  database,
  open,
  loading,
  onCancelClick,
  onConfirmClick,
  error,
  errorHeader,
}: Props) => {
  const [userConfirmed, setUserConfirmed] = useState(false);

  const handleUserConfirm = () => {
    setUserConfirmed(!userConfirmed);
  };

  let confirmText = loading ? 'Loading' : 'Resume';
  if (error) {
    confirmText = 'Try again';
  }

  return (
    <Dialog size="medium" open={open} onClose={onCancelClick}>
      <Dialog.Header className="tw-truncate">
        Are you sure you want to resume <DatabaseName Name={database.Name} DbId={database.DbId} />?
      </Dialog.Header>
      <Dialog.Content data-testid="resume-db-modal-content">
        {database.Tier !== Tier.FREE && <ModalTextContent database={database} />}
        <div className="tw-mt-4">
          <Checkbox
            data-testid="resume-confirm-checkbox"
            label="I understand"
            onChange={handleUserConfirm}
            checked={userConfirmed}
          />
        </div>
      </Dialog.Content>
      {error && (
        <Dialog.Content>
          <Alert
            data-testid="modal-error-message"
            type="danger"
            title={errorHeader}
            description={error}
            className="tw-mt-2"
          />
        </Dialog.Content>
      )}
      <Dialog.Actions>
        <Button
          onClick={onCancelClick}
          disabled={loading}
          data-testid="resume-close-button"
          color="neutral"
          fill="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirmClick}
          loading={loading}
          disabled={!userConfirmed}
          data-testid="resume-confirm-button"
        >
          {confirmText}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
