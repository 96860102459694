import globals from 'browser/globals';
import location from 'browser/location';
import { isProductAvailable } from 'entities/tenant';
import { parse } from 'query-string';
import { NavigationLocation } from 'state/navigation';
import { SessionState } from 'state/session-store';
import { Product } from 'types/product';
import { CloudProvider } from 'entities/database';
import Actions from 'actions';
import { DatabasesStore, SessionStore } from 'state';

export const percentageComplete = (current, total) => {
  const l = parseInt(current, 10);
  const t = parseInt(total, 10);
  const a = 100 + ((l - t) / t) * 100;
  return Math.round(a);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const sizeStringToBytesInt = sizeString => {
  const unitMap = {
    B: 10 ** 1,
    KB: 10 ** 3,
    MB: 10 ** 6,
    GB: 10 ** 9,
    TB: 10 ** 12,
  };
  const [, num, unit] = sizeString.match(/^(\d+)\s*([a-zA-Z])+$/);
  return parseInt(num, 10) * unitMap[unit.toUpperCase()];
};

export const asPercentageString = (
  partialValue: number | null | undefined,
  maxValue: number | null | undefined
) => {
  const value = partialValue || 0;
  if (maxValue) {
    const percentage = ((100 * value) / maxValue).toFixed();
    return `${value} / ${maxValue} (${percentage}%)`;
  }
  return `${value}`;
};

/**
 * Ensure there is always one space between the value and the unit
 * and replaces power of 2 based prefixes with corresponding power of 10 variant.
 *
 * e.g "8GB" => "8 GB", "16  GiB" => "16 GB"
 */
export const formatSizeString = (str: string | undefined): string | undefined => {
  const match = (str || '').match(/(\d+)\s*([A-Za-z]+)/);

  if (!match) {
    // Unknown input format
    return str;
  }

  const [, size, unit] = match;

  // Console API returns a mix of gibibyte / gigabyte units.
  // We spare the end user from such details to keep it simple.
  const powerOfTenUnit = unit
    .replace('KiB', 'KB')
    .replace('MiB', 'MB')
    .replace('GiB', 'GB')
    .replace('TiB', 'TB');

  return `${size} ${powerOfTenUnit}`;
};

export const scrollToFirstError = () => {
  setTimeout(() => {
    const inputErrors = globals.document.querySelectorAll<HTMLElement>(
      '.ndl-form-item.has-error > .form-msg'
    );
    const selectErrors = globals.document.querySelectorAll<HTMLElement>(
      '.ndl-dropdown > .error-text'
    );

    const nodes = [...inputErrors, ...selectErrors];
    let lowest = 0;
    let lowestNode = null;

    for (const node of nodes) {
      if (lowestNode === null || node.offsetTop < lowest) {
        lowest = node.offsetTop;
        lowestNode = node;
      }
    }

    if (lowestNode) {
      // Find the nearst ndl modal
      const ndlModal = lowestNode.closest('.ndl-modal-wrapper');
      const scrollable = ndlModal || globals.window;
      scrollable.scrollTo({
        top: lowestNode.offsetTop,
        behavior: 'smooth',
      });
    }
  }, 1);
};

export const getParameterFromURL = param => {
  if (location.search) {
    const params = new globals.URLSearchParams(location.search.substring(1));
    return params.get(param);
  }
  return null;
};

export const getHomePath = (session: SessionState) => {
  const urlProduct = parse(location.search)?.product;
  let inferredProduct;
  if (session.providerConfigs) {
    inferredProduct = isProductAvailable(session.providerConfigs, Product.AURA_DB)
      ? Product.AURA_DB
      : Product.AURA_DS;
  }
  const product = urlProduct ?? inferredProduct;
  return session.loggedIn && product ? `/?product=${product}#` : '/#';
};

export const constructBreadcrumbs = (breadcrumbLocation: NavigationLocation, homePath: string) => {
  const breadcrumbs = breadcrumbLocation.hash
    .slice(1)
    .split('/')
    .map(item => ({
      title: item,
      href: breadcrumbLocation.hash.slice(0, breadcrumbLocation.hash.indexOf(item)) + item,
    }));

  return [{ title: 'Neo4j Aura', href: homePath }, ...breadcrumbs];
};

export const marketplaceMessage = (cloudProvider: string) => {
  switch (cloudProvider) {
    case 'aws':
      return 'AWS Marketplace';
    default:
      return 'Marketplace';
  }
};

export const setMarketplaceQueryParameters = () => {
  const queryParams = globals.getUrlSearchParams();
  const cloudProviders: string[] = Object.values(CloudProvider);

  let customerId = '';
  let productId = '';
  let cloudProvider = '';

  for (const [key, value] of queryParams.entries()) {
    if (['aws_customer_id', 'azure_customer_id', 'gcp_customer_id'].includes(key)) {
      customerId = value;
    }
    if (['aws_product_id', 'azure_product_id', 'gcp_product_id'].includes(key)) {
      productId = value;
      cloudProvider = key.split(/_(.*)/s)[0];
    }
  }

  if (cloudProviders.includes(cloudProvider)) {
    const { email } = SessionStore.state;
    Actions.marketplace.setMarketplaceRegistrationLocalStorage(
      customerId,
      productId,
      cloudProvider,
      email
    );
    return true;
  }
  return false;
};

export const getDefaultSelfServeDatabaseName = () => {
  let instances = DatabasesStore.state.databases;
  const pattern = /^Instance(\d+)$/;

  if (instances.length === 0) {
    return 'Instance01';
  }

  // Find instance names of the format "InstanceXX"
  const numbers = instances.map(db => {
    const match = db.Name.match(pattern);
    return match ? parseInt(match[1]) : 0;
  });

  // Find the highest number used in those instance names and increment
  const maxNum = Math.max(...numbers);
  const nextNum = maxNum + 1;

  return 'Instance' + String(nextNum).padStart(2, '0');
};
