import React from 'react';

interface DatabaseNameProps {
  DbId: string;
  href?: string;
  Name: string;
}
const DatabaseName = ({ DbId, Name, href = null }: DatabaseNameProps) => {
  const Element = href ? 'a' : 'span';
  if (Name && Name.length > 0) {
    return (
      <Element href={href} className="db-name" data-testid="database-name" title={Name}>
        {Name}
      </Element>
    );
  }
  return (
    <Element href={href} className="db-name" data-testid="database-name">
      Unnamed Database: {DbId}
    </Element>
  );
};

export default DatabaseName;
