import React, { useState } from 'react';
import { Button, Dialog, FormSelect } from 'foundation';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { InternalTenant, PricingPlanName } from 'entities/tenant';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';
import { editTenantPricingPlan } from 'remote/resources/internal-tenant';
import { pricingPlanOptions } from '../constants';

export const schema = yup.object({
  pricingPlanName: yup
    .string()
    .oneOf([PricingPlanName.LEGACY_ENTERPRISE, PricingPlanName.VIRTUAL_DEDICATED_CLOUD])
    .required()
    .label('Pricing plan'),
});

export const validate = (
  data: EditPricingPlanFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(schema, data, onlyRequired || false);
};

interface EditPricingPlanFormData {
  pricingPlanName?: PricingPlanName;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  tenant: InternalTenant;
}

export const EditPricingPlanModal = ({ open, onClose, onSuccess, tenant }: Props) => {
  const [data, setData] = useState<EditPricingPlanFormData>({
    pricingPlanName: tenant.pricingPlanName,
  });
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();

  const handleClose = () => {
    setValidationError(null);
    setData({ pricingPlanName: tenant.pricingPlanName });
    onClose();
  };

  const handlePricingPlanChange = ({ value }) => setData({ pricingPlanName: value });

  const handleSubmit = () => {
    setValidationError(null);
    const error = validate(data);

    if (error) {
      setValidationError(error);
      return;
    }

    setLoading(true);

    editTenantPricingPlan(tenant.id, data.pricingPlanName)
      .then(() => onSuccess())
      .catch(e => defaultErrorHandler(e))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Header>Edit pricing plan</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-2">
        <FormSelect
          label="Pricing plan"
          value={data.pricingPlanName}
          options={pricingPlanOptions}
          onChange={handlePricingPlanChange}
          errorText={validationError?.pricingPlanName?.message}
          data-testid="select-pricing-plan"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={loading} data-testid="submit-pricing-plan">
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
