import React, { useEffect, useState } from 'react';
import { Divider, Alert, Checkbox } from 'foundation';
import * as yup from 'yup';

import { validateYup, Validation } from 'utils/validation';
import formatDollars from 'utils/format-dollars';

import { DatabaseNameInput, EncryptionKeySelect } from 'application/create-db/form-essentials';
import { Tier } from 'entities/database';
import { CloneToNewDatabaseFormData, CloneToNewDatabaseFormOptions } from './form-data';
import { availableRegionsForTier, getCloudProvider, PlanType } from 'entities/tenant';
import {
  EncryptionKey,
  EncryptionKeyRef,
  EncryptionKeyStatus,
  NEO4J_MANAGED_KEY,
} from 'types/encryption-keys';
import { CloneDatabaseChangeCmekWarning } from '..';
import { useCmeks } from 'remote/resources/encryption-keys';

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  confirmed: yup
    .bool()
    .required('You must check that your understand the costs of cloning a database')
    .isTrue('You must check that your understand the costs of cloning a database'),
});

export const validate = (
  data: CloneToNewDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => validateYup(schema, data, onlyRequired || false);

export const defaults = (options: CloneToNewDatabaseFormOptions): CloneToNewDatabaseFormData => {
  const tier = Tier.AURA_DSE;
  return {
    name: `${options.database.Name} Clone`,
    tier,
    region: availableRegionsForTier(options.providerConfigs, options.targetTier)[0]?.name,
    version: '5',
    size: options.databaseSizes[tier][0],
    confirmed: undefined,
    cloudProvider: getCloudProvider(options.providerConfigs),
    ...(options.planType === PlanType.ENTERPRISE
      ? {
          encryptionKeyRef: undefined,
        }
      : {}),
  };
};

interface Props {
  data: CloneToNewDatabaseFormData;
  options: CloneToNewDatabaseFormOptions;
  onChange: (data: CloneToNewDatabaseFormData) => any;
  validation: Validation<CloneToNewDatabaseFormData>;
  disabled?: boolean;
}

const EnterpriseDSFields = ({ data, onChange, validation, disabled, options }: Props) => {
  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });

  const handleCmkChange = (encryptionKeyRef: EncryptionKeyRef) =>
    onChange({ ...data, encryptionKeyRef });

  const handleConfirmedChange = ({ target: { checked } }) => {
    onChange({
      ...data,
      confirmed: showDifferentEncryptionWarning ? checked && differentEncryptionConfirmed : checked,
    });
    setIsDefaultConfirmed(checked);
  };

  const [isDefaultConfirmed, setIsDefaultConfirmed] = useState(false);
  const [showCmekWarning, setShowCmekWarning] = useState(false);
  const [differentEncryptionConfirmed, setDifferentEncryptionConfirmed] = useState(false);
  const [showDifferentEncryptionWarning, setShowDifferentEncryptionWarning] = useState(false);

  const handleConfirmedDifferentEncryptionKeyChange = ({ target: { checked } }) => {
    onChange({ ...data, confirmed: isDefaultConfirmed && checked });
    setDifferentEncryptionConfirmed(checked);
  };

  let applicableKeys: EncryptionKey[] = [];

  const cmeksQuery = useCmeks(options.tenant);

  const customKeys: EncryptionKey[] = cmeksQuery.isSuccess ? cmeksQuery.data : [];
  const readyKeys = customKeys.filter(k => k.status === EncryptionKeyStatus.READY);
  applicableKeys = readyKeys.filter(k => k.tier === data.tier);

  useEffect(() => {
    if (!options.tenant.capabilities.cmek) return;
    const currentEncryptionKey = data.encryptionKeyRef;
    const initialEncryptionKey = options.database?.EncryptionKey?.encryptionKeyRef;
    const hasInitialEncryption = !!initialEncryptionKey;
    const hasDifferentEncryption = hasInitialEncryption
      ? currentEncryptionKey !== initialEncryptionKey
      : currentEncryptionKey !== NEO4J_MANAGED_KEY;

    if (!options.isCloned) {
      if (currentEncryptionKey !== NEO4J_MANAGED_KEY) {
        setShowCmekWarning(true);
      } else {
        setShowCmekWarning(false);
      }
      return;
    }

    if (hasDifferentEncryption) {
      setShowCmekWarning(false);
      setShowDifferentEncryptionWarning(true);
    } else {
      setShowCmekWarning(currentEncryptionKey !== NEO4J_MANAGED_KEY);
      setShowDifferentEncryptionWarning(false);
    }
    onChange({ ...data, confirmed: false });
    handleConfirmedChange({ target: { checked: false } });
    setDifferentEncryptionConfirmed(false);
  }, [data.encryptionKeyRef]);

  const PriceDescription = ({ tier, memory, cost }) => {
    if (tier === Tier.AURA_DSE) {
      return <p className="tw-font-light">Please refer to your contract for pricing.</p>;
    }
    return (
      <p className="tw-font-light">
        This {memory} instance will cost {formatDollars(cost)} per hour while running.
      </p>
    );
  };

  return (
    <>
      <h5 className="tw-mb-4 tw-mr-2">Instance details</h5>

      <div>
        <Alert
          type="info"
          icon={true}
          description="Cloned AuraDS instances will create a version 5 Neo4j Database."
          className="tw-mb-6"
        />
      </div>

      <DatabaseNameInput
        value={data.name}
        validationError={validation?.name?.message}
        onChange={handleDatabaseNameChange}
        disabled={disabled}
      />

      {options.tenant.capabilities.cmek && (
        <div className="tw-my-4">
          <EncryptionKeySelect
            value={data.encryptionKeyRef}
            tier={data.tier}
            region={data.region}
            onChange={handleCmkChange}
            validationError={validation?.encryptionKeyRef?.message}
            disabled={disabled}
            tenant={options.tenant}
          />
        </div>
      )}
      {showDifferentEncryptionWarning && (
        <Alert
          type="warning"
          icon={true}
          description={
            <div className="tw-pb-1">
              <CloneDatabaseChangeCmekWarning
                keys={applicableKeys}
                encryptionKey={data.encryptionKeyRef}
              />
              <div className="tw-my-2 tw-ml-3">
                <Checkbox
                  label="I understand"
                  checked={differentEncryptionConfirmed}
                  onChange={handleConfirmedDifferentEncryptionKeyChange}
                  disabled={disabled}
                  data-testid="other-encryption-confirmation-check"
                />
              </div>
            </div>
          }
          className="tw-mb-6"
        />
      )}
      {options.tenant.capabilities.cmek && showCmekWarning && (
        <Alert
          type="warning"
          icon={true}
          description="By encrypting this instance with a Customer Managed Key, you take full responsibility for the management and security of the key. Neo4j cannot recover your data if the key is lost or invalid. There is a risk of permanent data loss."
        />
      )}

      <div className="tw-mx-auto console-w-fit">
        <Divider hidden />
        <PriceDescription
          tier={data.tier}
          memory={data.size.memory}
          cost={data.size.cost_per_hour}
        />
        <div className="tw-mt-4">
          <Checkbox
            label="I understand"
            checked={isDefaultConfirmed}
            onChange={handleConfirmedChange}
            disabled={disabled}
            data-testid="pricing-confirmation-check"
          />
        </div>
        {validation?.confirmed?.message && (
          <Alert type="danger" className="tw-mt-2">
            {validation?.confirmed?.message}
          </Alert>
        )}
      </div>
    </>
  );
};

export default EnterpriseDSFields;
