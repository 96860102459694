import React, { ChangeEvent } from 'react';
import { Channel, IdpType, SsoConfig } from 'types/sso-config';
import { Alert, Button, Checkbox, FormInput, FormSelect, Link } from 'foundation';
import Icon from 'components/ui/icons';
import { Validation } from 'utils/validation';
import { SsoConfigFormData } from './types';

export interface EnrichedSsoConfig extends SsoConfig {
  /**
   * Indicates that the client secret field needs to be populated.
   * Should be set to true in these scenarios:
   * - Creating backchannel config
   * - Updating config and channel is switched to backchannel
   *
   * We do not need the secret when updating a backchannel config as
   * the secret is already set
   */
  needsSecret: boolean;
  clientSecret?: string;
}

export enum SsoConfigFormAction {
  CREATE,
  UPDATE,
}

export interface CreateEditSsoConfigFormFieldsProps {
  originalConfig?: SsoConfig;
  config: SsoConfigFormData;
  onChange(data: SsoConfigFormData): void;
  validation: Validation<SsoConfigFormData>;
  formAction: SsoConfigFormAction;
  organizationId?: string;
}

const CreateEditSsoConfigFormFields = ({
  originalConfig,
  config,
  onChange,
  validation,
  formAction,
  organizationId,
}: CreateEditSsoConfigFormFieldsProps) => {
  const isEdit = formAction === SsoConfigFormAction.UPDATE;
  const isCreate = formAction === SsoConfigFormAction.CREATE;
  const updateData = (param: string, value: any) => onChange({ ...config, [param]: value });

  const handleDisplayNameChange = ({
    target: { value: displayName },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('displayName', displayName);
  };

  const handleIdpTypeChange = (idpType: IdpType) => {
    updateData('idpType', idpType);
  };

  const handleChannelChange = (channel: Channel) => {
    let needsSecret = false;
    if (isCreate) {
      // Need secret for creating back channel
      if (channel === Channel.BACK) {
        needsSecret = true;
      }
    } else {
      // Need secret for updating back channel if original config is front channel
      if (channel === Channel.BACK && originalConfig?.channel !== Channel.BACK) {
        needsSecret = true;
      }
    }

    onChange({
      ...config,
      channel,
      // Always reset clientSecret on change
      clientSecret: undefined,
      needsSecret,
    });
  };

  const handleClientSecretChange = ({
    target: { value: clientSecret },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('clientSecret', clientSecret);
  };

  const handleRoleMappingChange = ({
    target: { value: roleMapping },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('roleMapping', roleMapping);
  };

  const handleDiscoveryURIChange = ({
    target: { value: discoveryURI },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('discoveryURI', discoveryURI);
  };

  const handleIssuerChange = ({ target: { value: issuer } }: ChangeEvent<HTMLInputElement>) => {
    updateData('issuer', issuer);
  };

  const handleAuthorizationEndpointChange = ({
    target: { value: authorizationEndpoint },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('authorizationEndpoint', authorizationEndpoint);
  };

  const handleTokenEndpointChange = ({
    target: { value: tokenEndpoint },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('tokenEndpoint', tokenEndpoint);
  };

  const handleJwksURIChange = ({ target: { value: jwksURI } }: ChangeEvent<HTMLInputElement>) => {
    updateData('jwksURI', jwksURI);
  };

  const handleClientIDChange = ({ target: { value: clientID } }: ChangeEvent<HTMLInputElement>) => {
    updateData('clientID', clientID);
  };

  const handleEmailDomainChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const emailDomains = config.emailDomains.map((domain, i) => {
      if (i === index) {
        return event.target.value;
      } else {
        return domain;
      }
    });
    updateData('emailDomains', emailDomains);
  };

  const handleOrganizationLoginMethodChange = ({
    target: { checked: organizationLoginMethod },
  }) => {
    updateData('organizationLoginMethod', organizationLoginMethod);
  };

  const removeEmailDomainField = (index: number) => {
    const emailDomains = [...config.emailDomains].filter((_, i) => i !== index);
    updateData('emailDomains', emailDomains);
  };

  const addEmailDomainField = () => {
    const emailDomains = [...config.emailDomains, ''];
    updateData('emailDomains', emailDomains);
  };

  return (
    <>
      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Display Name"
          aria-label="Display name"
          data-testid="sso-config-display-name-input"
          value={config.displayName}
          onChange={handleDisplayNameChange}
          errorText={validation?.displayName?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          Users will see this name when they try to login to Aura Console or an instance via SSO.
        </div>
      </div>

      <div className="tw-mt-4">
        <FormSelect
          searchable={false}
          value={config.idpType}
          label="Idp Type"
          options={[
            {
              label: 'Okta',
              key: IdpType.OKTA,
              value: IdpType.OKTA,
            },
            {
              label: 'Microsoft Entra ID',
              key: IdpType.AAD,
              value: IdpType.AAD,
            },
          ]}
          onChange={idp => {
            handleIdpTypeChange(idp.value);
          }}
        />
      </div>

      <div className="tw-mt-4">
        <FormSelect
          data-testid="sso-config-channel-select"
          label="Channel"
          searchable={false}
          value={config.channel}
          options={[
            {
              label: 'Front channel',
              key: Channel.FRONT,
              value: Channel.FRONT,
            },
            {
              label: 'Back channel',
              key: Channel.BACK,
              value: Channel.BACK,
            },
          ]}
          onChange={option => {
            handleChannelChange(option.value);
          }}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {
            'Back channel means that SSO will use auth code flow instead of the implicit flow used by front channel. Back channel is more secure and preferred.'
          }
        </div>
        {config.channel === Channel.FRONT && (
          <Alert
            className="tw-mt-2"
            type="warning"
            description="Front channel sso configs are deprecated. If possible please use a back channel sso config"
          />
        )}
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Client ID"
          aria-label="SSO config client id"
          data-testid="sso-config-client-id-input"
          value={config.clientID}
          onChange={handleClientIDChange}
          errorText={validation?.clientID?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">{'Provided by your IdP.'}</div>
      </div>

      {config.channel === Channel.BACK && (
        <div className="tw-mt-4">
          <FormInput
            fluid
            label="Client Secret"
            placeholder={isEdit ? 'Replace current client secret' : undefined}
            data-testid="sso-config-client-secret-input"
            aria-label="SSO config client secret"
            value={config.clientSecret ?? ''}
            onChange={handleClientSecretChange}
            errorText={validation?.clientSecret?.message}
          />
          <div className="form-field-hint n-body-medium tw-mt-1">{'Provided by your IdP.'}</div>
        </div>
      )}

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Discovery URI"
          aria-label="SSO config discovery uri"
          data-testid="sso-config-discovery-uri-input"
          value={config.discoveryURI}
          onChange={handleDiscoveryURIChange}
          errorText={validation?.discoveryURI?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {
            'Provided by your IdP. For example "https://some-tenant.okta.com/.well-known/openid-configuration"'
          }
        </div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Issuer"
          aria-label="SSO config issuer"
          data-testid="sso-config-issuer-input"
          value={config.issuer}
          onChange={handleIssuerChange}
          errorText={validation?.issuer?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'Provided by your IdP. For example "https://some-tenant.okta.com"'}
        </div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Authorization Endpoint"
          aria-label="SSO config authorization endpoint"
          data-testid="sso-config-authorization-endpoint-input"
          value={config.authorizationEndpoint}
          onChange={handleAuthorizationEndpointChange}
          errorText={validation?.authorizationEndpoint?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'Provided by your IdP. For example "https://some-tenant.okta.com/oauth2/v1/authorize"'}
        </div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Token Endpoint"
          aria-label="SSO config token endpoint"
          data-testid="sso-config-token-endpoint-input"
          value={config.tokenEndpoint}
          onChange={handleTokenEndpointChange}
          errorText={validation?.tokenEndpoint?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'Provided by your IdP. For example "https://some-tenant.okta.com/oauth2/v1/token"'}
        </div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="JWKS URI"
          aria-label="SSO config jwks uri"
          data-testid="sso-config-jwks-uri-input"
          value={config.jwksURI}
          onChange={handleJwksURIChange}
          errorText={validation?.jwksURI?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {
            'URI for JSON Web Key Sets provided by your IdP. For example "https://some-tenant.okta.com/oauth2/v1/keys"'
          }
        </div>
      </div>

      <div className="tw-mt-4">
        <label className="ndl-form-item ndl-type-text ndl-medium">
          <div className="ndl-form-item-wrapper">
            <span className="ndl-form-label-text">Email Domains</span>
          </div>
        </label>
        {config.emailDomains.map((_, index) => (
          <FormInput
            key={index}
            className={index > 0 && 'tw-mt-2'}
            fluid
            aria-label="SSO config email domain"
            data-testid={`sso-config-email-domain-input-${index + 1}`}
            value={config.emailDomains[index]}
            onChange={event => handleEmailDomainChange(event, index)}
            rightIcon={
              <Icon
                name="TrashIconOutline"
                onClick={() => removeEmailDomainField(index)}
                data-testid={`remove-email-domain-field-${index + 1}`}
              />
            }
          />
        ))}
        {config.emailDomains.length > 0 && (
          <Alert type="warning" className="tw-mt-2">
            Email domains are only necessary when trying to enable{' '}
            <Link
              href="https://auth0.com/docs/authenticate/login/auth0-universal-login/identifier-first#define-home-realm-discovery-identity-providers"
              target="_blank"
            >
              Home Realm Discovery (HRD)
            </Link>
            . When setting an Email domain on an SSO config, HRD is enabled and will affect the
            login page for all users, even if the SSO config is NOT assigned to a tenant or
            organization. This means when you click save ALL users with this email domain will be
            redirected to this IDP to login after entering their email on the login page. Be sure
            you want this behavior before clicking save.
          </Alert>
        )}
        <Button
          color="neutral"
          className="tw-mt-2"
          fill="outlined"
          onClick={addEmailDomainField}
          data-testid="add-sso-config-email-domain-button"
        >
          Add Email Domain
        </Button>
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'For example "customerdomain.com". Used for redirection at login.'}
        </div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Role Mapping"
          aria-label="SSO config role mapping"
          data-testid="sso-config-role-mapping-input"
          value={config.roleMapping}
          onChange={handleRoleMappingChange}
          errorText={validation?.roleMapping?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'Role mapping only applies for Instance SSO. For example "group1"=role1;"group2"=role2'}
        </div>
      </div>
      {organizationId && (
        <div>
          <h6>
            By checking this box you will give this SSO access to login organization{' '}
            {organizationId}
          </h6>
          <div className="tw-mt-4">
            <Checkbox
              label="I understand"
              checked={config.organizationLoginMethod}
              onChange={handleOrganizationLoginMethodChange}
              data-testid="sso-organization-login-method-check"
              className="clone-existing-confirm-checkbox"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateEditSsoConfigFormFields;
