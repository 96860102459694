import React, { useState } from 'react';
import { Button, FormInput, FormSelect } from 'components/foundation';
import { validateYup, Validation } from 'utils/validation';
import OrganizationResource, { CreateOrganizationEntity } from 'remote/resources/organizations';
import * as yup from 'yup';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { useNotify } from 'state/notifications';
import Actions from 'actions';
import { PlanType, Profile, organizationProfileOptions } from 'types/organization';

const schema = yup.object({
  name: yup
    .string()
    .required()
    .min(3)
    .max(50)
    .matches(
      /^[a-z0-9][a-z0-9-]+[a-z0-9]$/,
      'Only lowercase letters, numbers, and hyphens are allowed. Must be at least 3 characters long.'
    )
    .label('Internal name'),
  displayName: yup
    .string()
    .required()
    .min(3)
    .max(30)
    .label('Display name'),
  planType: yup
    .string()
    .required()
    .oneOf(Object.values(PlanType))
    .label('Plan type'),
  profile: yup
    .string()
    .required()
    .oneOf(Object.values(Profile))
    .label('Profile'),
});

type Data = Partial<CreateOrganizationEntity>;

const defaults = (): Data => {
  return {
    name: undefined,
    displayName: undefined,
    planType: PlanType.ENTERPRISE,
    profile: Profile.EXTERNAL,
  };
};

const validate = (data: Data) => {
  return validateYup(schema, data);
};

const CreateOrganizationForm = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaults());
  const [validation, setValidation] = useState<Validation<Data>>({});
  const defaultErrorHandler = useDefaultErrorHandler();

  const handleNameChange = e => {
    const value = e.target.value;
    setData(prev => ({ ...prev, name: value }));
  };

  const handleDisplayNameChange = e => {
    const value = e.target.value;
    setData(prev => ({ ...prev, displayName: value }));
  };

  const handlePlanTypeChange = ({ value }) => {
    setData(prev => ({ ...prev, planType: value }));
  };

  const handleProfileChange = ({ value }) => {
    setData(prev => ({ ...prev, profile: value }));
  };

  const handleSubmit = async () => {
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }

    setLoading(true);

    try {
      const org = await OrganizationResource.create(data as CreateOrganizationEntity);

      Actions.navigate.push({ hash: `#admin/organizations/${org.id}` });
    } catch (e) {
      if (e.response.status === 409) {
        notify.error(`An organization with name '${data.name}' already exists.`);
      } else {
        defaultErrorHandler(e);
      }
    }

    setLoading(false);
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <h5>Create a New Organization</h5>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-max-w-[500px]">
        <FormInput
          label="Organization internal name"
          helpText="Only lowercase letters, numbers, and hyphens"
          value={data.name || ''}
          errorText={validation.name?.message}
          onChange={handleNameChange}
          fluid
          data-testid="org-name-input"
        />
        <FormInput
          label="Organization display name"
          fluid
          value={data.displayName || ''}
          errorText={validation.displayName?.message}
          onChange={handleDisplayNameChange}
          data-testid="org-display-name-input"
        />
        <FormSelect
          label="Organization plan type"
          fluid
          data-testid="org-plan-type-select"
          options={Object.values(PlanType).map(v => ({ label: v, value: v, key: v }))}
          value={data.planType || ''}
          errorText={validation.planType?.message}
          onChange={handlePlanTypeChange}
          helpText="Controls which tenants types can be created/linked to the org"
        />
        <FormSelect
          label="Organization profile"
          fluid
          data-testid="org-profile-select"
          options={organizationProfileOptions.map(option => ({ ...option }))}
          value={data.profile || ''}
          errorText={validation.profile?.message}
          onChange={handleProfileChange}
          helpText="Controls which tenants types can be created/linked to the org"
        />
      </div>
      <div>
        <Button onClick={handleSubmit} loading={loading} data-testid="org-submit-button">
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateOrganizationForm;
