import formatDollars from 'utils/format-dollars';
import { Alert, IconButton, LoadingSpinner, TextInput, Tag } from 'foundation';
import Actions from 'actions';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useTracking } from 'react-tracking';
import { TransformedUsage, Filter, DateFilter } from 'types/usage';
import { useSession } from 'store';
import FilterReportsMenu from './filter-reports-menu';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import { ReportsTable } from './reports-table';
import { MAXIMUM_DAYS } from './const';

export interface TotalBillingUsageProps {
  usage: TransformedUsage;
}

const TotalBillingUsage = () => {
  const session = useSession();
  const tracking = useTracking();
  const { capabilities } = session.tenant;
  const [searchFilter, setSearchFilter] = useState('');
  const [usage, setUsage] = useState<TransformedUsage>({
    totalCost: '0',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState<Filter[] | DateFilter[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const { consumption_reporting: hasConsumptionReportingEnabled } = capabilities;
  const [showCostDisclaimerBanner, setShowCostDisclaimerBanner] = useState<boolean>(true);
  const [showUsageInfoBanner, setShowUsageInfoBanner] = useState<boolean>(true);
  const [showSecondariesInfoBanner, setShowSecondariesInfoBanner] = useState<boolean>(true);

  const getUsageByFilters = (filters: Filter[] | DateFilter[]) => {
    setRequestPending(true);
    setErrorMessage(null);

    Actions.billingUsage
      .fetch(session.tenant.id, filters)
      .then(filteredUsage => {
        setUsage({
          ...usage,
          totalCost: filteredUsage.totalCost,
          discount: filteredUsage.discount || null,
          breakdown: filteredUsage.breakdown,
          hasPreviousPricingPlan: filteredUsage.hasPreviousPricingPlan || null,
        });
        let newOutstandingAmount = parseFloat(filteredUsage.totalCost);

        if (filteredUsage.discount) {
          newOutstandingAmount -= parseFloat(filteredUsage.discount.dollarAmountOff);
        }
        setOutstandingAmount(newOutstandingAmount);
      })
      .catch(e => {
        setErrorMessage(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        setRequestPending(false);
      });
  };

  useEffect(() => {
    getUsageByFilters(filters);
  }, [filters]);

  const openFiltering = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    tracking.trackEvent({
      action: 'open_filter_billing_reports',
    });

    setAnchorEl(e.currentTarget);
  };

  if (isLoading) {
    return <LoadingSpinner expand className="tw-w-full" />;
  }

  if (
    (!usage.breakdown || usage?.breakdown?.length === 0) &&
    !requestPending &&
    !errorMessage &&
    filters.length === 0
  ) {
    return (
      <Alert
        type="info"
        name="notes"
        icon
        className="tw-my-4"
        data-testid="billing-usage-report-no-data"
      >
        {hasConsumptionReportingEnabled && filters.length === 0
          ? `There is no usage data for the last ${MAXIMUM_DAYS} days.`
          : 'There is no usage data for this month.'}
      </Alert>
    );
  }

  return (
    <div data-testid="billing-usage-report" className="tw-flex tw-flex-col tw-gap-y-1">
      {hasConsumptionReportingEnabled && (
        <>
          <div className="tw-flex tw-justify-between">
            <div className="tw-flex tw-justify-content-start tw-items-align-start">
              <TextInput
                aria-label="Reports search"
                placeholder="Search"
                leftIcon={<MagnifyingGlassIconOutline />}
                value={searchFilter}
                onChange={value => setSearchFilter(value.target.value)}
                className="tw-pr-1"
              />
              <IconButton
                title="Filter Reports"
                data-testid="filter-reports"
                aria-label="Filter Reports"
                iconName="FunnelIconOutline"
                onClick={openFiltering}
                disabled={requestPending}
              />
              <FilterReportsMenu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                requestPending={requestPending}
                filters={filters}
                onUpdate={(filters: Filter[] | DateFilter[]) => {
                  setFilters([...filters]);
                  setAnchorEl(null);
                }}
                onClose={() => {
                  setAnchorEl(null);
                }}
              />
            </div>
            {!requestPending && (
              <div>
                <span className="n-body-medium tw-text-palette-neutral-text-weaker">
                  Total credits used:
                </span>
                <span className="n-label tw-text-palette-neutral-text-weaker tw-ml-1">
                  {usage.totalCost}
                </span>
              </div>
            )}
          </div>
          {errorMessage && (
            <Alert
              className="tw-mt-2"
              type="danger"
              data-testid="filter-usage-error-message"
              description="There was an error on fetching results"
            />
          )}
          <div>
            {filters.map((filter, index) => (
              <Tag
                key={index}
                onRemove={() => {
                  if (requestPending) return;
                  setFilters(prevState => prevState.filter((_, i) => i !== index));
                }}
                removeable={true}
              >
                <span>{filter.label}</span>
              </Tag>
            ))}
          </div>
        </>
      )}
      {usage?.breakdown && (
        <ReportsTable
          breakdown={usage.breakdown}
          isLoading={requestPending}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      )}
      {!hasConsumptionReportingEnabled && !requestPending && (
        <div
          className="tw-my-6 tw-ml-auto tw-grid tw-gap-x-6 tw-gap-y-2"
          style={{
            width: 'fit-content',
            gridTemplateColumns: 'max-content max-content',
          }}
        >
          {usage?.discount && (
            <>
              <div>
                <span>Subtotal:</span>
              </div>
              <div className="tw-justify-self-end tw-font-mono" data-testid="subtotal-amount">
                {formatDollars(usage.totalCost)}
              </div>
              <div>
                <div>
                  <div>Discount:</div>
                  <div
                    className="n-body-medium tw-text-palette-neutral-text-weaker"
                    data-testid="discount-promotion-name"
                  >
                    {usage.discount.promotionName}
                  </div>
                </div>
              </div>
              <div className="tw-justify-self-end tw-font-mono" data-testid="discount-amount">
                - {formatDollars(usage.discount.dollarAmountOff)}
              </div>
            </>
          )}
          <div>
            {outstandingAmount < 0
              ? 'In-credit balance so far this month:'
              : 'Amount due so far this month:'}
          </div>
          <div className="tw-justify-self-end tw-font-mono tw-font-bold" data-testid="final-amount">
            {formatDollars(Math.abs(outstandingAmount))}
          </div>
        </div>
      )}

      {!requestPending && !errorMessage && (
        <>
          {showCostDisclaimerBanner && usage.hasPreviousPricingPlan && (
            <Alert
              type="warning"
              name="notes"
              icon
              className="tw-mb-1"
              closeable
              onClose={() => setShowCostDisclaimerBanner(false)}
            >
              <p>
                The displayed data does not account for any change in pricing. Consumed credits are
                calculated based on the currently applied pricing contract of the tenant.
              </p>
            </Alert>
          )}
          {showUsageInfoBanner && (
            <Alert
              type="info"
              name="notes"
              icon
              closeable
              onClose={() => setShowUsageInfoBanner(false)}
            >
              <p>
                <span className="tw-font-bold">GB-hours:</span> Databases are measured as the number
                of hours they are running (regardless of whether they are used), multiplied by the
                memory size of the database in gigabytes (GB).
              </p>
              <p className="tw-mt-6">
                If a database is not run for an exact number of hours, the usage figure is rounded
                up.
              </p>
            </Alert>
          )}
          {showSecondariesInfoBanner && hasConsumptionReportingEnabled && (
            <Alert
              type="info"
              name="notes"
              icon
              className="tw-mt-1"
              closeable
              onClose={() => setShowSecondariesInfoBanner(false)}
            >
              <p>Usage for secondaries is not included in this view.</p>
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default TotalBillingUsage;
