import { SessionState } from 'state/session-store';
import { covers } from 'store';
import { Action } from 'types/user';

export const isMetricsIntegrationEnabled = (session: SessionState): boolean => {
  const { tenant, permissions } = session;

  if (!tenant) return false;

  return (
    tenant.capabilities.metrics_integration &&
    covers(permissions, Action.READ, `api/${tenant.id}/metrics`) &&
    covers(permissions, Action.READ, `api/${tenant.id}/*/metrics`)
  );
};
