import { AuthData } from 'actions/login-actions';
import globals from 'browser/globals';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import logger from 'logger';
import localStorage from 'browser/localStorage';

export const hasReloaded = () => {
  if (!globals.window.performance) {
    return false;
  }
  const legacy =
    globals.window.performance &&
    globals.window.performance.navigation &&
    globals.window.performance.navigation.type === 1;

  if (legacy) {
    return legacy;
  }

  const experimental =
    globals.window.performance.getEntriesByType &&
    globals.window.performance.getEntriesByType('navigation') &&
    globals.window.performance.getEntriesByType('navigation').length > 0 &&
    (globals.window.performance.getEntriesByType('navigation')[0] as any).type === 'reload';
  return experimental;
};

export const AuthDataKey = 'auth.data';

export const AuthBearerTokensKey = 'auth.bearerTokens';

export const loadStoredAuthBearerTokens = () => {
  const serialized = localStorage.getItem(AuthBearerTokensKey);
  try {
    return JSON.parse(serialized);
  } catch (e) {
    if (serialized) {
      logger.error('Failed to deserialize auth token', serialized, e);
    }
  }
  return null;
};

export const storeAuthBearerToken = (bearerToken: string) => {
  const ssoOrgId = jwtDecode<JwtPayload & { org_id?: string }>(bearerToken).org_id;
  localStorage.setItem(
    AuthBearerTokensKey,
    JSON.stringify({
      ...loadStoredAuthBearerTokens(),
      [ssoOrgId]: {
        token: bearerToken,
        ssoOrgId: ssoOrgId,
      },
    })
  );
};
export const clearStoredAuthData = () => {
  localStorage.removeItem(AuthDataKey);
};

export const clearStoredAuthBearerTokens = () => {
  localStorage.removeItem(AuthBearerTokensKey);
};

export const storeAuthData = (authData: AuthData) => {
  localStorage.setItem(AuthDataKey, JSON.stringify(authData));
};

export const loadStoredAuthData = (): AuthData | null => {
  const serialized = localStorage.getItem(AuthDataKey);
  try {
    return JSON.parse(serialized);
  } catch (e) {
    if (serialized) {
      logger.error('Failed to deserialize auth token', serialized, e);
    }
  }
  return null;
};
