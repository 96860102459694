import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { validateYup } from 'utils/validation';
import cx from 'classnames';
import { useLocation, usePermissions, useSession } from 'store';
import Actions from 'actions';
import {
  TenantType,
  Tenant,
  TenantSummary,
  PlanType as TenantPlanType,
  tierDisplayName,
} from 'entities/tenant';
import { SessionState } from 'state/session-store';
import {
  Button,
  Menu,
  MenuItem,
  CopyTextToClipBoardButton,
  TextInput,
  LoadingSpinner,
  Form,
  Label,
  IconButton,
} from 'foundation';
import Icon from 'ui/icons';

import './tenant-switcher.css';
import { useThemePalette } from 'utils/hooks';
import { Action } from 'types/user';
import { parse } from 'query-string';
import { Organization, PlanType as OrganizationPlanType } from 'types/organization';
import OrganizationResources, {
  useUserOrganizationsListQuery,
} from 'remote/resources/organizations';
import { useCanViewOrganizationSummary } from 'components/pages/organization-settings/helpers';
import { useTenantsQuery } from 'remote/resources/tenants';
import { Tier } from 'entities/database';
import { UseQueryResult } from 'react-query';

// TODO: Most if not all nullish-checking can be removed once Organizations are required
const INDEPENDENT_TENANT_KEY = '_ungrouped';
interface OrganizationTenants {
  organization?: Organization;
  tenants: Tenant[];
}

export const TenantSwitcher = () => {
  const session = useSession();
  const currentTenantId = session.currentTenant;
  const tenantsQuery: UseQueryResult<Tenant[]> = useTenantsQuery();
  const organizationsQuery = useUserOrganizationsListQuery();
  const [organizationTenants, setOrganizationTenants] = useState<{
    [org: string]: OrganizationTenants;
  }>({});
  const location = useLocation();
  const buttonRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const query = parse(location.search);

  useEffect(() => {
    let orgTenantsMap: {
      [org: string]: OrganizationTenants;
    } = Object.fromEntries(
      organizationsQuery.data?.map(org => [org.id, { organization: org, tenants: [] }]) ?? []
    );
    tenantsQuery.data?.forEach(tenant => {
      if (tenant.organizationId && Object.keys(orgTenantsMap).includes(tenant.organizationId)) {
        orgTenantsMap[tenant.organizationId].tenants.push(tenant);
      } else {
        if (INDEPENDENT_TENANT_KEY in orgTenantsMap) {
          orgTenantsMap[INDEPENDENT_TENANT_KEY].tenants.push(tenant);
        } else {
          orgTenantsMap[INDEPENDENT_TENANT_KEY] = { organization: undefined, tenants: [tenant] };
        }
      }
    });
    setOrganizationTenants(orgTenantsMap);
  }, [organizationsQuery.data, tenantsQuery.data]);

  const refetchQuery = () => {
    tenantsQuery.refetch();
    organizationsQuery.refetch();
  };

  const [selectedTenantId, setSelectedTenantId] = useState(currentTenantId);
  const { allow } = usePermissions();

  const handleSelectTenant = tenantId => {
    if (tenantId !== currentTenantId) {
      setSelectedTenantId(tenantId);
      Actions.navigate.push({ search: { ...query, tenant: currentTenantId } });
      Actions.tenants.set(tenantId);
      setMenuOpen(false);
      refetchQuery();
    }
  };

  useEffect(() => {
    if (query.tenant !== currentTenantId) {
      Actions.navigate.replace({ search: { ...query, tenant: currentTenantId } });
    }
  }, [query, query.tenant, currentTenantId]);

  if (!tenantsQuery.isSuccess || !organizationsQuery.isSuccess) {
    return null;
  }

  const selectedTenant = tenantsQuery.data.find(tenant => tenant.id === selectedTenantId);
  const selectedOrganization = Object.values(organizationTenants).find(organizationTenantsItem =>
    organizationTenantsItem.tenants.some(tenant => tenant.id === selectedTenantId)
  )?.organization;

  return (
    <>
      <div className="tw-mr-4">
        <Button
          fill="text"
          color="neutral"
          title="Select a tenant"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
          ref={buttonRef}
          className="tw-whitespace-nowrap"
          data-testid="tenant-switcher"
        >
          {[selectedOrganization?.displayName, selectedTenant?.name]
            .filter(name => !!name)
            .join(' / ') || 'Select a tenant'}
          <Icon
            className="tw-inline-flex regular"
            name="ChevronDownIconOutline"
            title="Select a tenant"
            style={{
              marginLeft: '0.25rem',
              transform: menuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transitionDuration: '150ms',
            }}
          />
        </Button>
        <Menu
          open={menuOpen}
          value={selectedTenant?.id || ''}
          onClose={() => setMenuOpen(false)}
          className="tenant-switcher-menu"
          data-testid="tenant-switcher-menu"
          anchorEl={buttonRef.current}
        >
          <OrganizationMenuItems
            organizationTenants={organizationTenants}
            session={session}
            selectedTenant={selectedTenant}
            onSelectTenant={handleSelectTenant}
            onUpdate={refetchQuery}
            allowTenantUpdate={allow(Action.UPDATE, `namespaces/${currentTenantId}`)}
          />
        </Menu>
      </div>
    </>
  );
};

interface OrganizationMenuItemsProps {
  organizationTenants: { [org: string]: OrganizationTenants };
  session: SessionState;
  selectedTenant?: TenantSummary;
  onSelectTenant: (tenantId: string) => void;
  onUpdate: () => void;
  allowTenantUpdate: boolean;
}

const OrganizationMenuItems = ({
  organizationTenants,
  selectedTenant,
  onSelectTenant,
  onUpdate,
  allowTenantUpdate,
}: OrganizationMenuItemsProps) => {
  let organizationTenantsDataClone = { ...organizationTenants };
  const isEnterprise = (organizationTenantsItem: OrganizationTenants) =>
    organizationTenantsItem.organization?.planType === OrganizationPlanType.ENTERPRISE ||
    organizationTenantsItem.tenants.some(tenant => tenant.planType === TenantPlanType.ENTERPRISE);

  if (
    Object.values(organizationTenantsDataClone).some(organizationTenantsItem =>
      isEnterprise(organizationTenantsItem)
    )
  ) {
    organizationTenantsDataClone = Object.fromEntries(
      Object.values(organizationTenantsDataClone)
        .filter(
          organizationTenantsItem =>
            !organizationTenantsItem.organization ||
            organizationTenantsItem.organization?.planType === OrganizationPlanType.ENTERPRISE
        )
        .map(organizationTenantsItem => [
          organizationTenantsItem.organization?.id ?? INDEPENDENT_TENANT_KEY,
          organizationTenantsItem,
        ])
    );
    const hasIndependentTenants = INDEPENDENT_TENANT_KEY in organizationTenantsDataClone;
    if (hasIndependentTenants) {
      organizationTenantsDataClone[INDEPENDENT_TENANT_KEY].tenants = organizationTenantsDataClone[
        INDEPENDENT_TENANT_KEY
      ].tenants.filter(tenant => tenant.planType === TenantPlanType.ENTERPRISE);
    }
  }

  const menuItems = Object.values(organizationTenantsDataClone)
    .sort(
      (a: OrganizationTenants, b: OrganizationTenants) =>
        a.organization?.name.localeCompare(b.organization?.name) ?? 1
    )
    .map((organizationTenantsItem, i) =>
      // _ungrouped may be empty post-filtering
      organizationTenantsItem.tenants.length > 0 ? (
        <div
          key={organizationTenantsItem.organization?.id ?? INDEPENDENT_TENANT_KEY}
          className="console-menu-item-group"
        >
          <OrganizationMenuItem
            index={i}
            organizationTenants={organizationTenantsItem}
            selectedTenant={selectedTenant}
            allowTenantUpdate={allowTenantUpdate}
            onSelectTenant={onSelectTenant}
            onUpdate={onUpdate}
          />
        </div>
      ) : null
    );

  return <>{menuItems}</>;
};
interface OrganizationMenuItemProps {
  index: number;
  organizationTenants: OrganizationTenants;
  selectedTenant: TenantSummary;
  allowTenantUpdate: boolean;
  onSelectTenant: (tenantId: string) => void;
  onUpdate: () => void;
}

const orgNameSchema = yup.object({
  name: yup
    .string()
    .required()
    .min(3)
    .max(30),
});

interface UpdateOrgNameData {
  name: string;
}

const validateOrgName = (
  data: UpdateOrgNameData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(orgNameSchema, data, onlyRequired || false);
};

const OrganizationMenuItem = ({
  index,
  organizationTenants,
  selectedTenant,
  allowTenantUpdate,
  onSelectTenant,
  onUpdate,
}: OrganizationMenuItemProps) => {
  const session = useSession();
  const [orgDisplayName, setOrgDisplayName] = useState(
    organizationTenants.organization?.displayName
  );
  const { allow } = usePermissions();
  const [errorMessage, setErrorMessage] = useState(null);
  const [editOrgDisplayName, setEditOrgDisplayName] = useState(false);
  const [loading, setLoading] = useState(false);
  const allowOrgUpdate = allow(
    Action.UPDATE,
    `organizations/${organizationTenants.organization?.id}`
  );
  const displayOrganizationSettingsIcon =
    useCanViewOrganizationSummary(organizationTenants.organization?.id) &&
    !!organizationTenants.organization;

  const onEditOrgNameClick = e => {
    e.preventDefault();
    setEditOrgDisplayName(true);
  };
  const handleOrgNameChange = e => {
    setErrorMessage(null);
    setOrgDisplayName(e.target.value);
    if (!e.target.value) {
      setErrorMessage('Organization name cannot be empty');
    }
  };
  const submitOrgName = e => {
    e.preventDefault();
    const error = validateOrgName({ name: orgDisplayName });
    if (error) {
      setErrorMessage(error.name?.message);
      return;
    }

    if (!orgDisplayName) {
      setErrorMessage('Organization name cannot be empty');
      return;
    }
    setLoading(true);

    OrganizationResources.update(organizationTenants.organization?.id, {
      displayName: orgDisplayName,
    })
      .then(() => {
        onUpdate();
        setEditOrgDisplayName(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const menuItems = organizationTenants.tenants.map(tenant => (
    <TenantMenuItem
      tenant={tenant}
      key={tenant.id}
      isSelected={tenant.id === selectedTenant?.id}
      onSelectTenant={onSelectTenant}
      onUpdate={onUpdate}
      allowTenantUpdate={allowTenantUpdate}
    />
  ));

  return (
    <div
      data-testid={`organization-${organizationTenants.organization?.id ?? INDEPENDENT_TENANT_KEY}`}
    >
      {index > 0 && <Menu.Divider />}
      {organizationTenants.organization && (
        <div className="tw-flex tw-flex-row tw-justify-between tw-mb-2">
          <div className="tw-flex tw-flex-row">
            {!editOrgDisplayName && (
              <>
                <Menu.Subheader title={organizationTenants.organization.displayName} />
                {allowOrgUpdate &&
                  selectedTenant.organizationId === organizationTenants.organization?.id && (
                    <Icon
                      className="tw-cursor-pointer tw-mt-2"
                      title="Edit organization name"
                      name="PencilIconOutline"
                      onClick={onEditOrgNameClick}
                      data-testid="edit-org-display-name"
                      size="small"
                    />
                  )}
              </>
            )}
            {editOrgDisplayName && (
              <Form onSubmit={submitOrgName}>
                <TextInput
                  data-testid={`org-name-${organizationTenants.organization.id}`}
                  errorText={errorMessage}
                  fluid
                  readOnly={!editOrgDisplayName}
                  onChange={handleOrgNameChange}
                  aria-label="Organization name"
                  value={orgDisplayName}
                  {...(allowOrgUpdate &&
                    !loading && {
                      rightIcon: (
                        <Icon
                          className="tw-cursor-pointer"
                          title="Change organization name"
                          name="CheckIconOutline"
                          onClick={submitOrgName}
                          data-testid="submit-org-display-name"
                        />
                      ),
                    })}
                  {...(loading && {
                    rightIcon: <LoadingSpinner size="small" />,
                  })}
                  size="medium"
                />
              </Form>
            )}
            {organizationTenants.organization.planType === OrganizationPlanType.ENTERPRISE &&
              !session.featureToggles.enable_vdc &&
              !editOrgDisplayName && (
                <Label
                  data-testid="enterprise-label"
                  className={`enterprise-label tw-self-center ${
                    allowOrgUpdate ? 'tw-ml-4' : 'tw-ml-2'
                  }`}
                >
                  {tierDisplayName(organizationTenants.tenants?.[0], Tier.ENTERPRISE)}
                </Label>
              )}
          </div>
          {displayOrganizationSettingsIcon && !editOrgDisplayName && (
            <IconButton
              href={`#organizations/${organizationTenants.organization.id}/summary`}
              iconName="Cog6ToothIconOutline"
              aria-label="Organization settings"
              size="medium"
              className="tw-self-center tw-ml-4"
              title="Organization settings"
            />
          )}
        </div>
      )}
      <Menu.Items>{menuItems}</Menu.Items>
    </div>
  );
};

interface TenantMenuItemProps {
  tenant: TenantSummary;
  isSelected: boolean;
  allowTenantUpdate: boolean;
  onUpdate: () => void;
  onSelectTenant: (tenantId: string) => void;
}

const schema = yup.object({
  name: yup
    .string()
    .required()
    .max(30),
});

interface UpdateFriendlyNameData {
  name: string;
}

const validate = (
  data: UpdateFriendlyNameData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(schema, data, onlyRequired || false);
};

const TenantMenuItem = ({
  tenant,
  isSelected,
  allowTenantUpdate,
  onUpdate,
  onSelectTenant,
}: TenantMenuItemProps) => {
  const palette = useThemePalette();
  const firstGradient = palette.primary.bg.weak + '00';
  const secondGradient = palette.primary.bg.weak + 'FF';
  const [friendlyName, setFriendlyName] = useState(tenant.name);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editTenantFriendlyName, setEditTenantFriendlyName] = useState(false);
  const [loading, setLoading] = useState(false);
  const onEditFriendlyNameClick = e => {
    e.preventDefault();
    setEditTenantFriendlyName(true);
  };

  const handleFriendlyNameChange = e => {
    setErrorMessage(null);
    setFriendlyName(e.target.value);
    if (!e.target.value) {
      setErrorMessage('Tenant name cannot be empty');
    }
  };

  const submitFriendlyName = e => {
    e.preventDefault();
    const error = validate({ name: friendlyName });
    if (error) {
      setErrorMessage(error.name?.message);
      return;
    }

    if (!friendlyName) {
      setErrorMessage('Tenant name cannot be empty');
      return;
    }
    setLoading(true);
    Actions.namespaces
      .editTenant(tenant.id, { friendlyName: friendlyName })
      .then(() => {
        onUpdate();
        setEditTenantFriendlyName(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MenuItem
      title={
        <>
          <Form onSubmit={submitFriendlyName}>
            <TextInput
              data-testid={`friendly-name-${tenant.id}`}
              errorText={errorMessage}
              fluid
              {...(!editTenantFriendlyName && {
                style: { cursor: 'pointer' },
              })}
              readOnly={!editTenantFriendlyName}
              onChange={handleFriendlyNameChange}
              aria-label="Tenant name"
              value={friendlyName}
              {...(isSelected &&
                allowTenantUpdate &&
                !editTenantFriendlyName && {
                  rightIcon: (
                    <Icon
                      className="tw-cursor-pointer"
                      title="Edit tenant name"
                      name="PencilIconOutline"
                      onClick={onEditFriendlyNameClick}
                      data-testid="edit-tenant-friendly-name"
                    />
                  ),
                })}
              {...(isSelected &&
                allowTenantUpdate &&
                editTenantFriendlyName &&
                !loading && {
                  rightIcon: (
                    <Icon
                      className="tw-cursor-pointer"
                      title="Change friendly name"
                      name="CheckIconOutline"
                      onClick={submitFriendlyName}
                      data-testid="submit-tenant-friendly-name"
                    />
                  ),
                })}
              {...(loading && {
                rightIcon: <LoadingSpinner size="small" />,
              })}
              size="small"
            />
          </Form>
        </>
      }
      description={
        <span className="tw-items-center">
          {tenant.id}
          {tenant.tenantType === TenantType.N4GCP && (
            <div>Managed by GCP Project ID: {tenant.googleProjectId}</div>
          )}
          {tenant.tenantType === TenantType.MARKETPLACE_AWS && <div>Managed by AWS</div>}
          {tenant.tenantType === TenantType.MARKETPLACE_AZURE && <div>Managed by Azure</div>}
          <CopyTextToClipBoardButton
            className="copy-to-clipboard tw-absolute tw-right-0 tw-top-8 tw-w-9"
            text={tenant.id}
            iconButtonProps={{
              title: 'Copy Tenant ID to clipboard',
              'aria-label': 'Copy Tenant ID to clipboard',
              onClick: e => e.stopPropagation(),
              size: 'small',
            }}
            style={{
              background: `linear-gradient(to right, ${firstGradient} 0%, ${secondGradient} 10%, ${secondGradient})`,
              borderRadius: 8,
            }}
          />
        </span>
      }
      onClick={() => {
        onSelectTenant(tenant.id);
      }}
      className={cx('tw-relative', {
        selected: isSelected,
      })}
      icon={
        <Icon
          name="CheckIconOutline"
          title="Selected"
          size="regular"
          color="primary"
          style={{
            visibility: isSelected ? 'auto' : 'hidden',
          }}
        />
      }
      data-testid={`tenant-${tenant.id}`}
    />
  );
};
