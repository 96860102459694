import {
  InternalTenant,
  TenantType,
  TenantProfile,
  BillingMethod,
  PricingPlanName,
} from 'entities/tenant';
import ApiClient from 'remote/api-client';

const transformResponse = (response: any): InternalTenant => {
  return {
    id: response.namespace_id,
    internalName: response.internal_name,
    isolationIds: response.isolation_ids,
    friendlyName: response.friendly_name,
    tenantType: response.namespace_type,
    planType: response.plan_type,
    suspended: response.suspended,
    suspensionReason: response.suspension_reason,
    mfaRequired: response.mfa_required,
    ssoConfigIds: response.sso_config_ids,
    salesforceCppId: response.salesforce_customer_project_profile_id,
    stripeUrl: response.stripe_customer_url,
    hasUnpaidInvoices: response.has_unpaid_invoices,
    billingMethod: response.billing_method,
    tenantProfile: response.namespace_profile,
    organizationId: response.organization_id,
    pricingPlanName: response.pricing_plan_name,
    pricingPlanStartDate: response.pricing_plan_start_date,
  };
};

export const getTenant = (tenantId: string): Promise<InternalTenant> =>
  ApiClient.get(`/internal/tenants/${tenantId}`)
    .ignoreAllErrors()
    .promise()
    .then(transformResponse);

export interface InternalCreateTenantPayload {
  internalName: string;
  friendlyName: string;
  tenantType: TenantType;
  isolationIds: string[];
  requireMFA: boolean;
  salesforceCppId: string | undefined;
  minimumNeo4jVersion: string;
  organizationId: string;
  pricingPlanName?: PricingPlanName;
}

export const createTenant = (payload: InternalCreateTenantPayload): Promise<InternalTenant> =>
  ApiClient.post('/internal/tenants')
    .issue({
      internal_name: payload.internalName,
      friendly_name: payload.friendlyName,
      isolation_ids: payload.isolationIds,
      namespace_type: payload.tenantType,
      mfa_required: payload.requireMFA,
      salesforce_customer_project_profile_id: payload.salesforceCppId,
      minimum_neo4j_version: payload.minimumNeo4jVersion,
      organization_id: payload.organizationId,
      pricing_plan_name: payload.pricingPlanName,
    })
    .ignoreAllErrors()
    .promise()
    .then(transformResponse);

export interface InternalEditTenantPayload {
  friendlyName: string;
  /** If undefined, it will not be altered. If null, it will be removed. */
  salesforceCppId: string | undefined | null;
  tenantProfile: TenantProfile;
  billingMethod: BillingMethod;
}

export const editTenant = (
  tenantId: string,
  payload: InternalEditTenantPayload
): Promise<InternalTenant> =>
  ApiClient.patch(`/internal/tenants/${tenantId}`)
    .issue({
      friendly_name: payload.friendlyName,
      salesforce_customer_project_profile_id: payload.salesforceCppId,
      namespace_profile: payload.tenantProfile,
      billing_method: payload.billingMethod,
    })
    .ignoreAllErrors()
    .promise()
    .then(transformResponse);

export const editTenantPricingPlan = (
  tenantId: string,
  pricingPlanName: PricingPlanName
): Promise<InternalTenant> =>
  ApiClient.patch(`/internal/tenants/${tenantId}`)
    .issue({
      pricing_plan_name: pricingPlanName,
    })
    .ignoreAllErrors()
    .promise()
    .then(transformResponse);

export const createSalesforceCpp = (tenantId: string): Promise<InternalTenant> =>
  ApiClient.post(`/internal/tenants/${tenantId}/salesforce-mte-cpp`)
    .ignoreAllErrors()
    .promise()
    .then(transformResponse);

export default {
  getTenant,
  createTenant,
  editTenant,
  editTenantPricingPlan,
  createSalesforceCpp,
};
